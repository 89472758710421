//@ts-ignore
import React from 'react'
import { Outlet } from 'react-router-dom'
import SideBarNavigator from '../../Routes/SideBarNavigation/SideBarNavigator'
import DashboardHeader from './DashboardHeader'

type Props = {}

const MainDashboard = (props: Props) => {
  return (
    <section className='flex'>
        <SideBarNavigator/>
        <section className='w-full h-screen overflow-scroll '>
            <DashboardHeader/>
            <section className=' pl-7 pr-7  lg:w-11/12'>
            <Outlet/>
            </section>
        </section>
    </section>
  )
}

export default MainDashboard