
const divStyle = {
  height: "70px",
  width: "113px",
};

interface mapicontype {
  imageurl: string;
  name: string;
}

export const Mapicon = (props: mapicontype) => {
  return (
    <div>
      <div style={divStyle}>
        <img
          style={{
            width: "70px",
            height: "70px",
            borderRadius: "50%",
            margin: "auto",
            objectFit:"cover"
          }}
          src={props.imageurl}
          alt={props.name}
        />
        {/* <MiniAvatar url={props.imageurl} width="70px" height="70px"/> */}
      </div>
      <div
        style={{
          padding: "5px",
          width: "100%",
          textAlign: "center",
          font: "Helvetica",
          fontSize: "16px",
          fontWeight: "400",
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          marginTop: "10px",
          color: "#000000",          
        }}
      >
        {props.name}
      </div>
    </div>
  );
};
