
/* eslint-disable */
// @ts-nocheck
import React, { useRef, useState } from "react";
import { BiPlus } from "react-icons/bi";

import { HiTrash } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Column, useTable, useSortBy } from "react-table";
import { Edit } from "../../Assets/Home.svg";
import FilledButton from "../../Components/Button/FilledButton/FilledButton";
import ConfirmationModal from "../../Components/ConfirmationModal";
import EmptyStateData from "../../Components/EmptyStateData";
import MiniAvatar from "../../Components/MiniAvatar";
import { showError, showSuccess } from "../../Components/Notifications";
import TableDataLoader from "../../Components/TableDataLoader";
import { deleteAdminByID } from "../../Services/CreateServices";
import "./Table.css";
import TableOptionsCard from "./TableOptionsCard";
type Props = {
  ToggleScreen: () => void;
  data: any;
  loading: boolean;
  fetchData : () => void
};

const Table = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [tableloading , setTableLoading] = useState(false)
  const navigate = useNavigate()
  const deleteAdminID = useRef<string | null>()
  const data: any = React.useMemo(() => props.data, [props.data]);

  const MenuOptions = [
    {
      value: "Edit",
      icon: <Edit />,
      handleClick: (id: string) => {
        console.log("this is edit", id);
        navigate(`addProfile/${id}`)

      },
    },
    {
      value: "Delete",
      icon: <HiTrash />,
      handleClick: (id: string) => {
        setOpen(true);
        deleteAdminID.current = id
      },
    },
  ];

  const Togglemodal = () => {
    setOpen(prev => !prev);
  } 

  const deleteAdmin = async() => {
    setTableLoading(true)
    try {
      const resp = await deleteAdminByID(deleteAdminID.current as string)
      console.log(resp)
      showSuccess(resp.data.message,resp.data.message)
    } catch (error) {
      showError("","")
    }finally{
      setTableLoading(false)
      setOpen(false)
      props.fetchData()
     
      
    }
    console.log(deleteAdminID)
  };


  const columns: Array<
    Column<{
      name: string;
      email: string;
      fcm_token: string;
      profile_img: string;
      _id: string;
    }>
  > = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name", // accessor is the "key" in the data
        Cell: (props) => {
          console.log("celll render props", props);
          return (
            <div className="text-left text-white font-hel font-light tracking-wide flex alignCenter">
              <MiniAvatar url={props.cell.row.original.profile_img} />
              {props.value}
            </div>
          );
        },
      },

      {
        Header: "E-mail",
        accessor: "email",
        Cell: (props) => {
          return (
            <div className="text-left text-white font-hel font-light tracking-wide">
              {props.value}
            </div>
          );
        },
      },

      {
        Header: "Shift Time",
        accessor: "shift_time",
        Cell: (props) => {
          return (
            <TableOptionsCard
              options={MenuOptions}
              id={props.cell.row.original._id}
              value={props.value}
            />
          );
        },
      },
    ],
    []
  );

  const { getTableProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );
  return (
    <>
      <section>
        <ConfirmationModal
          open={open}
          onClose={Togglemodal}
          onConfrim={deleteAdmin}
          onOpen={() => {}}
          title=" Are you sure you want to delete this profile"
          loading={tableloading}
          subtitle=""
        />
        <div
          className="flex"
          style={{ justifyContent: "space-between", alignItems: "flex-start" }}
        >
          <h1 className="lg:text-4xl text-2xl font-bold mb-10 text-[#EFEFEF]">
            Admin Panel
          </h1>
          <div onClick={props.ToggleScreen}>
            <FilledButton
              children={"Add Profile"}
              loading={false}
              style={{
                paddingLeft: "1rem",
                paddingRight: "1.5rem",
                marginTop: 0,
                borderRadius: "0.8rem",
              }}
              icon={<BiPlus size={24} className="mr-2" />}
            />
          </div>
        </div>
      </section>
      <div className="bg-[#1A1D1F] min-h-table overflow-auto p-7 2xl:max-w-6xl rounded-lg">
        <table
          {...getTableProps()}
          className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <thead className="text-xs text-white uppercase bg-[#26292A]">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index, columns) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`px-6 ${
                      index === columns.length ? "text-center" : "text-left"
                    }  py-5 font-medium whitespace-nowrap`}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tr className="p-10">
            <th className="p-2"></th>
          </tr>
         
           {
             !props.loading && rows.length > 0 && <tbody>
           
             {rows.map((row, i) => {
               prepareRow(row);
               return (
                 <tr
                   {...row.getRowProps()}
                   className="border-t  border-[#6F767E] border-opacity-50"
                 >
                   {row.cells.map((cell) => {
                     return (
                       <td
                         {...cell.getCellProps()}
                         className="pl-6  py-4 m-0 "
                       >
                         {cell.render("Cell")}
                       </td>
                     );
                   })}
                 </tr>
               );
             })}
           </tbody>
           }
           
        </table>
        {
          props.loading && <div className="w-full  h-44 ">
          <TableDataLoader/>
          </div> 
        }
        {
          
          !props.loading  &&  rows.length < 1 && 
          <div className="w-full h-44 ">

          <EmptyStateData/>
          </div>
         
        }
      </div>
    </>
  );
};

export default Table;
