
/* eslint-disable */
// @ts-nocheck
import  { useEffect } from 'react'

type Props = {
    placeholder ?: string;
    label ?: string;
    error ?: boolean;
    value ?: string,
    id : string,
    onChange ?: (value: string) => void
    type ?: string,
    name : string,
    setFieldValue : any,
    touched : any
}

const GooglePlacesInput = (props: Props) => {
    const errorStyles = props.error ? "text-red-400" : "text-white"
    useEffect(() => {
        var input =  document.getElementById(props.id) as HTMLInputElement
        var autocomplete = new google.maps.places.Autocomplete(
            input,
            );
            autocomplete.addListener('place_changed', function() {

                // Get place info
                var place = autocomplete.getPlace();
              
                // Do whatever with the value!
                console.log("tufail",place?.geometry?.location?.lat());
                console.log("tufail",place);
                GetLatlong(place?.formatted_address)
              });
              // return removeEventListener(autocomplete, () => {

              // })
    }, [])

    function GetLatlong(value : string | undefined) {
        var geocoder = new google.maps.Geocoder();
        var address = value
        props.setFieldValue(props.name,address)
        geocoder.geocode({
          'address': address
        }, function(results, status) {
      
          if (status === google.maps.GeocoderStatus.OK && results) {
            var latitude = results[0].geometry.location.lat();
            var longitude = results[0].geometry.location.lng();
            props.setFieldValue(props.id,{
              coordinates: [latitude, longitude]
            })
            console.log("pakda cm", latitude,longitude)
          }
        });
      }
  return (
    <div className='flex flex-col items-start w-full'>
    <label className='mb-0 text-white'>{props.label}</label>
    <input 
    id={props.id}
    className={`
      border-0
      border-b
      ${
        props.error ? "border-red-400" : "border-[#969696]"
      }
      bg-transparent
      ${errorStyles}
      w-full
      outline-none py-2
     placeholder:text-gray-600`}
     type={props.type}
     onChange={(event) => {
      props.setFieldValue(props.name,event.target.value)
     }}
      value={props.value}
      placeholder={props.placeholder}  />

</div>
  )
}

export default GooglePlacesInput