/* eslint-disable */
import axiosInstance from "./axios";

export const addCarDetails = (payload: {}) => {
  const resp = axiosInstance.post("car", payload);
  return resp;
};

export const addDriverDetails = (payload: {}) => {
  const resp = axiosInstance.post(`driver/newdriver`, payload);
  return resp;
};

export const getAllDrivers = () => {
  const resp = axiosInstance.get(`driver`);
  return resp;
};

export const getAllAdmins = (hotelID: string) => {
  const resp = axiosInstance.get(`admin/${hotelID}/hotels`);
  // const resp = axiosInstance.get(`admin/629a52089551ed2afbaa542c/hotels`)
  return resp;
};

export const deleteAdminByID = (adminID: string) => {
  const defaultPayload = {
    disabled: true,
  };
  const resp = axiosInstance.put(`admin/${adminID}`, defaultPayload);
  return resp;
};

export const deleteDriverByID = (driverID: string) => {
  const defaultPayload = {
    disabled: true,
  };
  const resp = axiosInstance.put(`driver/${driverID}`, defaultPayload);
  return resp;
};

export const addContentMgm = (payload: {}) => {
  const resp = axiosInstance.post("contentmanagment", payload);
  return resp;
};

export const getAllBookings = () => {
  const resp = axiosInstance.get(`bookcar/all`);
  return resp;
};

export const getBookingByID = (id: string) => {
  const resp = axiosInstance.get(`bookcar/${id}`);
  return resp;
};

export const getCarCategories = () => {
  const resp = axiosInstance.get(`category`);
  return resp;
};

export const getAdminByID = (id: string) => {
  const resp = axiosInstance.get(`admin/${id}`);
  return resp;
};

export const adminSignup = (payload: {}) => {
  const resp = axiosInstance.post(`auth/signup`, payload);
  return resp;
};

export const updateAdmin = (id: string, payload: {}) => {
  const resp = axiosInstance.put(`admin/${id}`, payload);
  return resp;
};

export const chooseCar = (id: string) => {
  const resp = axiosInstance.get(`choosecar/category/${id}`);

  // const resp = axiosInstance.get(`carbycategory/${id}`)
  return resp;
};

export const getDriversOnDuty = () => {
  const resp = axiosInstance.get(`driver`);
  return resp;
};

export const bookcar = (payload: {}) => {
  const resp = axiosInstance.post("bookcar", payload);
  return resp;
};

export const updateBookCar = (id: string, payload: {}) => {
  const resp = axiosInstance.put(`bookcar/${id}`, payload);
  return resp;
};

export const updateCarDetails=(id:string,payload:{})=>{
const resp=axiosInstance.put(`car/${id}`,payload)
}


export const deleteBookedCar = (id: string) => {
  const resp = axiosInstance.delete(`bookcar/${id}`);
  return resp;
};

export const getAllCars = () => {
  const resp = axiosInstance.get(`car`);
  return resp;
};

export const getDriver = () => {
  const resp = axiosInstance.get("driver");
};

export const getDriverByID = (driverID: string) => {
  const resp = axiosInstance.get(`driver/${driverID}`);
  return resp;
};

export const guestPriority = (driverID: string) => {
  const body = {
    priority: 1,
  };
  const resp = axiosInstance.put(`bookcar/updatePriority/${driverID}`, body);
  return resp;
};

export const getLocationByHotel = () => {
  const resp = axiosInstance.get("location");

  return resp;
};

export const deleteCarbyId=(id:string)=>{
    const resp =axiosInstance.delete(`car/${id}`)
    return resp
}


export const updateDriverDetailsById=(id:string,payload:{})=>{

  const resp=axiosInstance.put(`driver/${id}`,payload)
  return resp
}

export const getBookingDetails = (id:string) => {
  const resp = axiosInstance.get(`bookcar/${id}`);
  return resp;
};

export const getDriverReports = (id:string, day:string) => {
  const resp = axiosInstance.get(`rating/driver/detail?day=${day}&driver=${id}`);
  return resp;
};