import emptylogo from '../Assets/emptybox.png'
type Props = {}

const EmptyStateData = (props: Props) => {
  return (
    <section
    className="w-full h-full
  flex justify-center items-center
   border-dotted rounded-sm border-gray-400 "
  >
      <div className='flex flex-col items-center'>
      <img src={emptylogo} alt="no data found" className="w-16"></img>
   <h2 className='font-hel text-white '>
       no data found
   </h2>
      </div>
 
  </section>
  )
}

export default EmptyStateData