
/* eslint-disable */
// @ts-nocheck
const LocalStorageService = (function () {
  function _setToken(token: string | number) {
    localStorage.setItem("token", JSON.stringify(token));
  }


  function _getAccessToken() {
    const Token = _getParsedData("token")
    return Token;
  }

  function _setUser(user:{}) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  function _setRefreshTokenTime() {
    let expirationLength = 1 * 3600000
    const now = Date.now() + expirationLength
    localStorage.setItem("refreshTokenTime", JSON.stringify(now))
  }


  function _getParsedData (key : string) {
    let unParsedData = localStorage.getItem(key)
    if(!unParsedData) return null
    const parsedData = JSON.parse(unParsedData);
    return parsedData
  }

  function _getRefreshToken() {
    const time = _getParsedData("refreshTokenTime")
    return time;
  }

  function _clearToken() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("refreshTokenTime")
  }

  function _clearDynamically(key : string) {
      localStorage.removeItem(key)
  }

  return {
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    setRefreshTokenTime : _setRefreshTokenTime,
    getRefreshTokenTime : _getRefreshToken,
    clearToken: _clearToken,
    setUser: _setUser,
    clearDynamically : _clearDynamically,
    getParsedData:_getParsedData
  };
})();
export default LocalStorageService;
