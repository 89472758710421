
/* eslint-disable */
// @ts-nocheck
import { Form, Formik } from "formik";
import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FilledButton from "../../Components/Button/FilledButton/FilledButton";
import { showError, showSuccess } from "../../Components/Notifications";
import { addBookingForCarSchema } from "../../Configs/validation";
import { bookcar, getBookingByID, updateBookCar } from "../../Services/CreateServices";
import ExtendedDrive from "./AdditionalDetails/ExtendedDrive";
import GuestInformationDetails from "./BookingDetails/GuestInformationDetails";
import ChooseCarDetails from "./ChooseCarDetails/ChooseCarDetails";
import LocalStorageService   from "../../Services/LocalStorageService"

type Props = {};
interface Values {
  guestName: string;
  guestEmail: string;
  guestRoomNo: string;
  guestContactNo: string;
  pick_location: { coordinates: number[] };
  formatted_pick_address: string;
  drop_location: { coordinates: number[] };
  formatted_drop_address: string;
  car_category: any;
  car: string;
  driver: string;
}

const initialValues = {
  guestName: "",
  guestEmail: "",
  guestRoomNo: "",
  guestContactNo: "",
  pick_location: { coordinates: [] },
  formatted_pick_address: "",
  drop_location: { coordinates: [] },
  formatted_drop_address: "",
  car_category: {},
  car: "",
  driver: "",
};

const Home = (props: Props) => {
  const [bookingDetails, setBookingDetails] = useState<Values>(initialValues);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  console.log("IDENT: ", id)

  useLayoutEffect(() => {
    id && getBookingDetails();
  }, [id]);

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    console.log("ree")
  })
  const getBookingDetails = async () => {
    setLoading(true);
    try {
      console.log('id', id);
      const resp = await getBookingByID(id || "");
      console.log("booking repsponse", resp.data.data);
      setBookingDetails(resp.data.data);
    } catch (error) {
      console.log("errro in getting bookingdetails");
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="my-12">
      <Formik
        enableReinitialize
        initialValues={{
          guestName: bookingDetails?.guestName,
          guestEmail: bookingDetails?.guestEmail,
          guestRoomNo: bookingDetails?.guestRoomNo,
          guestContactNo: bookingDetails?.guestContactNo,
          pick_location: {
            coordinates: [
              bookingDetails?.pick_location.coordinates[0],
              bookingDetails?.pick_location.coordinates[1],
            ],
          },
          formatted_pick_address: bookingDetails?.formatted_pick_address,
          drop_location: {
            coordinates: [
              bookingDetails?.drop_location.coordinates[0],
              bookingDetails?.drop_location?.coordinates[1],
            ],
          },
          formatted_drop_address: bookingDetails?.formatted_drop_address,
          car_category: bookingDetails?.car_category?._id,
          car: bookingDetails?.car,
          driver: bookingDetails?.driver,
        }}
        validationSchema={addBookingForCarSchema}
        onSubmit={async (values: Values, { setSubmitting, resetForm }) => {
          try {
            const hotelId=LocalStorageService.getParsedData('user').hotel
            // needed to add additional key in the payload 
            const bookCarResp = id ? await updateBookCar(id,{...values,hotel:hotelId}) : await bookcar({...values,hotel:hotelId});
            console.log(bookCarResp);
            showSuccess(bookCarResp?.data?.message || "successfully done", "check the booking list");
            // navigating to home page after 2 seconds of success.
          
              navigate("/")
          } catch (error) {
            const { message } = error as Error; // I removed : { message: string } because it should be infered
            console.log(message)
            showError("something went wrong", "");
          }finally {
            resetForm()
          }
        }}
      >
        {({
          isSubmitting,
          handleChange,
          errors,
          values,
          setFieldValue,
          resetForm,
          touched,
        }) => {
          console.log(values, errors);
          return (
            <Form autoComplete="off">
              <main className={`flex flex-col  ${loading ? "opacity-60" : ""}`}>
                <GuestInformationDetails
                  loading={loading}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  errors={errors}
                  values={values}
                  touched={touched}
                />
                <ChooseCarDetails
                  handleChange={handleChange}
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                />
                <ExtendedDrive
                  setFieldValue={setFieldValue}
                  touched={touched}
                  handleChange={handleChange}
                  errors={errors}
                  values={values}
                />
                <section className="flex space-x-10 mt-14">
                  <FilledButton
                    handleClick={() => {
                      // resetForm();
                      navigate("/");
                    }}
                    type="button"
                    filled
                  >
                    Cancel
                  </FilledButton>
                  <FilledButton loading={isSubmitting}>Confirm</FilledButton>
                </section>
              </main>
            </Form>
          );
        }}
      </Formik>
    </main>
  );
};

export default Home;
