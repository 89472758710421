import { useState, useEffect } from "react";
import Table from "./Table";
import {
  getAllBookings,
  getDriversOnDuty,
} from "../../Services/CreateServices";

type Props = {};

const GuestList = (props: Props) => {
  // eslint-disable-next-line
  // const navigate = useNavigate()
  const [bookingData, setBookingData] = useState<any>([]);
  // eslint-disable-next-line
  const [driversOnDuty, setDriversOnDuty] = useState<{}[]>([]);
  const [loading, setLoading] = useState(true);

  // pagination

  const [paginatedData, setPaginatedData] = useState<any>([]);
  const [paginationCount, setPaginationCount] = useState<number>(1);


  

  useEffect(() => {
    var arr = [];
    for (let i = (paginationCount - 1) * 10; i < paginationCount * 10; i++) {
      if (i < bookingData.length) {
        console.log(i, bookingData[i], "booking dataatt");
        arr.push(bookingData[i]);

        setPaginatedData([...arr]);
      }
    }
    if(bookingData.length===0)
    {
      setPaginatedData([])
    }
  }, [bookingData, paginationCount]);

  console.log(bookingData,"paginated data")

  useEffect(() => {
    getAllBookingsData();
  }, []);

  const getAllBookingsData = async () => {
    setLoading(true);
    try {
      const allbookings = getAllBookings();
      const driversOnDuty = getDriversOnDuty();
      const allresponses = await Promise.all([allbookings, driversOnDuty]);
      console.log("csdc", allresponses);
      console.log(allresponses[0].data.data,"checking in guest ququw");
      setBookingData(
        allresponses[0]?.data?.data
          .sort((a: any, b: any) => {
            return b.priority - a.priority;
          })
          .filter((e: any) => {
            return e.status !== "completed";
          })
      );
      setDriversOnDuty(allresponses[1]?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="my-12">
      <Table
        fetchData={getAllBookingsData}
        data={paginatedData}
        loading={loading}
        paginationCount={paginationCount}
        setPaginationCount={setPaginationCount}
        bookingdata={bookingData}
      />
    </main>
  );
};

export default GuestList;
