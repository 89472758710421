import React from "react";
import { Loader } from "../../../Components/Button/FilledButton/FilledButton";
import GooglePlacesInput from "../../../Components/Input/GooglePlacesInput";
import Input from "../../../Components/Input/Input";

type Props = {
  handleChange: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  loading : boolean
};

const GuestInformationDetails = ({
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
  loading
}: Props) => {
  return (
    <>
      <section>
        <h1 className="lg:text-4xl text-2xl font-bold mb-10 text-white">
          Book your car now
        </h1>
      </section>
      <section className="bg-[#1A1D1F] relative rounded-md flex flex-col space-y-10 p-10">
      <div className={`absolute z-20 top-0 left-0 h-full w-full rounded-lg
        ${0 ? "" : "hidden"}
         bg-[#1A1D1F] flex justify-center items-center`}>
        <Loader/>
        </div>
        <section className="flex md:space-x-20 md:flex-nowrap space-y-7 md:space-y-0 flex-wrap items-center">
          <Input
            label="Guest Name"
            name="guestName"
            onChange={handleChange}
            placeholder="Guest Name"
            value={values.guestName}
            touched={touched.guestName}
            error={errors.guestName}
          />
          <Input
            label="Room Number"
            placeholder="Guest Room number"
            name="guestRoomNo"
            touched={touched.guestRoomNo}
            onChange={handleChange}
            error={errors.guestRoomNo}
            value={values.guestRoomNo}
          />
        </section>
        <section className="flex md:space-x-20 md:flex-nowrap space-y-7 md:space-y-0 flex-wrap items-center">
          <Input
            label="Email Id"
            name="guestEmail"
            error={errors.guestEmail}
            onChange={handleChange}
            touched={touched.guestEmail}
            placeholder="xyz@example.com"
            value={values.guestEmail}
          />
          <Input
            label="Contact Number"
            error={errors.guestContactNo}
            type="text"
            touched={touched.guestContactNo}
            name="guestContactNo"
            onChange={handleChange}
            value={values.guestContactNo}
            placeholder="+97-68792233"
          />
        </section>
        <section className="flex md:space-x-20 md:flex-nowrap space-y-7 md:space-y-0 flex-wrap items-center">
          <GooglePlacesInput value={values.formatted_pick_address}
          error={errors.formatted_pick_address}
          touched={touched.formatted_pick_address}
           name="formatted_pick_address" id="pick_location" label="Pick up Location" setFieldValue={setFieldValue} />
          <GooglePlacesInput value={values.formatted_drop_address} 
          error={errors.formatted_drop_address}
          touched={touched.formatted_drop_address}
           name="formatted_drop_address" id="drop_location" label="Drop Location"  setFieldValue={setFieldValue}/>
        </section>
      </section>
    </>
  );
};

export default GuestInformationDetails;
