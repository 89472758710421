/* eslint-disable */
import axios from "axios";
import { REACT_APP_BASE_URL } from "../Utils/Constants";
import LocalStorageService from "./LocalStorageService";

 
const baseURL = REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  // .. congigure axios baseURL
  baseURL: `${baseURL}`
});


axiosInstance.interceptors.request.use(function (config:any) {
  config.headers.Authorization = `Bearer ${LocalStorageService.getAccessToken()}`;

  if(LocalStorageService.getParsedData('user'))
  {
    config.headers.hotel=LocalStorageService.getParsedData('user').hotel;
  }
  config.headers["Content-Type"] = 'application/json';
  config.headers.Accept = 'application/json';
  
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  // function (error) {
  //   const originalRequest = error.config;
  //   if (error?.response?.status === 401 && !originalRequest._retry) {
  //     originalRequest._retry = true;

  //     return (
  //       axiosInstance
  //         .post("/general/refresh-token", {
  //           token: localStorageService.getRefreshToken(),
  //         })
  //         // .then((res) => {})
  //         .then((res) => {
  //           if (res.status === 200) {
  //             // 1) put token to LocalStorage

  //             localStorageService.setToken(res.data);
  //             // 2) Change Authorization header
  //             originalRequest.headers.Authorization =
  //               "Bearer " + res.data?.access_token;
  //             // originalRequest.defaults.headers.common["Authorization"] =
  //             //   "Bearer " + localStorageService.getAccessToken();
  //             // 3) return originalRequest object with Axios.
  //             return axios(originalRequest);
  //           }
  //         })
  //     );
  //   }
  //   // return Error object with Promise
  //   return Promise.reject(error);
  // }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status == 403) {
      LocalStorageService.clearToken()
    }
    return Promise.reject(error);
  }
);

  export default axiosInstance