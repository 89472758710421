
/* eslint-disable */
// @ts-nocheck
import { Menu, MenuDivider, MenuItem } from '@szhsin/react-menu'
import { HiDotsVertical } from 'react-icons/hi'
import MiniAvatar from '../../Components/MiniAvatar'
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { Bin, Edit, Up } from '../../Assets/Home.svg';
// type Props = {
//     action : () => void
//     data : any
//     value : string
// }
type Props = {
    value: string;
    options: {}[];
    id: string
};

const MenuOptions = [{
    option  : "Prioritise",
    icon : <Up/>
},
{
    option  : "Delete",
    icon : <Bin/>
},

]


const TableOptionsCard = ({ value, options, id }: Props) => {
    console.log(value, 'Value')
    return (
        <section className='flex items-center justify-end  mr-3'>
            <div>
                <MiniAvatar url={value.driver_image}/>
            </div>
            <div className='flex flex-col space-y-1 '>
                <span className='text-white font-hel font-normal whitespace-nowrap tracking-wider'>
                    {value.driver_name}
                </span>
                <span className='text-[#979797] font-hel  whitespace-nowrap'>
                    {value.driver_age}
                </span>
            </div>

            <div className='ml-4'>
                <TableMenu options={options} id={id} />
            </div>
        </section>
    )
}

export default TableOptionsCard


const TableMenu = ({ options, id }: { options: any, id: string }) => {
    return <Menu className="bg-black" menuStyle={{
        backgroundColor: '#26292A',
        color: "white"
    }} menuButton={<div>
        <HiDotsVertical size={25} className="cursor-pointer text-white" />
    </div>}>
        {
            options.map((option, index, options) => {
                return <div key={index * 23}>
                    <MenuItem onClick={() => {
                        option.handleClick(id)
                    }} className={(events) => `flex space-x-3 text-white ${events.hover ? `text-black` : `text-white`}`}>
                        {option.icon} <span className=''>{option.option}</span>
                    </MenuItem>
                    <MenuDivider className={`text-white ${index == options.length - 1 && 'hidden'}`} />
                </div>
            })
        }

        <MenuDivider />

    </Menu>
}