
/* eslint-disable */
// @ts-nocheck
import  { memo, useEffect, useState } from "react";
import CarDetailsCard from "../../../Components/MiniCards/CarDetailsCard";
import DriverDetailsCard from "../../../Components/MiniCards/DriverDetailsCard";
import carImg from "../../../Assets/preview.png";
import { chooseCar, getCarCategories } from "../../../Services/CreateServices";
import { ICategories } from "../../CarScreens/AddCar/AddCarDetails";
import Select from "react-select";
import { StylesConfig, GroupBase } from 'react-select';

type Props = {
  handleChange: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
};

interface IAttachedDetails {
  driver : {},
  car : {}
}

const customStyles: StylesConfig<any, false, GroupBase<any>> = {
  control: (base, state) => ({
    ...base,
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    color: 'white',
    marginBottom: -10,
    boxShadow: 'none',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? '#555' : '#333',
    color: 'white',
    '&:hover': {
      backgroundColor: '#555',
    },
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#333",
  }),
  singleValue: (base) => ({
    ...base,
    color: 'white',
  }),
};

const ChooseCarDetails = (props: Props) => {
  const [categories, setCategories] = useState<ICategories[]>([]);
  const [attachDetails, setAttachDetails]  = useState<IAttachedDetails | null>(null)
  const [loading, setloading] = useState(false)

  useEffect(() => {
    getCarCategoriesData();
  }, []);

  useEffect(() => {
    if(props.values.car_category)
    getAttachedCarToDriver()
  },[props.values.car_category])


  const getCarCategoriesData = async () => {
    try {
      const categoryResp = await getCarCategories();
      console.log(categoryResp.data.data,"car category check");
      setCategories(categoryResp.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      // if (!id) return;
      // getCarDetails();
    }
  };

  const getAttachedCarToDriver = async() => {
    setloading(true)
    try {
      const chooseCarResp = await chooseCar(props.values.car_category)
      console.log("choose car attached",chooseCarResp.data.data[0])
      if(chooseCarResp?.data?.data[0]){
      
        setAttachDetails(chooseCarResp?.data?.data[0])
        props.setFieldValue("car",chooseCarResp.data.data[0].car._id)
        
        props.setFieldValue("driver",chooseCarResp.data.data[0].driver._id)
       
      } else {
        setAttachDetails(null)
        props.setFieldValue("car","")
        props.setFieldValue("driver","")
       
        
      }

    } catch (error) {
        console.log(error)
    } finally {
      setloading(false)
    }
  }

  console.log("choose car props", props.values)

  const transformCategoryData = (data: any) => {
    return {
      value: data._id,
      label: data.name
    };
  };

  // finding the matched caterogy
  const matchedCategory = categories.find(category => category._id === props.values.car_category);

  // if found so changing the category data to select format
  const transformedCategory = matchedCategory ? transformCategoryData(matchedCategory) : null;

  return (
    <>
      <section>
        <h1 className="lg:text-4xl text-2xl  font-bold my-10 text-white">
          Choose your Car
        </h1>
      </section>
      <section className="flex md:space-x-8  md:flex-row flex-col">
        <section className="flex-1">
          <span className="text-white">Category</span>
          {/* <select
            // defaultValue={props.values.car_category}
            value={props.values.car_category}
            onChange={(evt) => {
              // alert(JSON.stringify(evt, null, 2));
              console.log("vinayak==> ", evt.target.value)
              props.setFieldValue("car_category",evt.target.value)
            }}
            className={`bg-transparent text-white border-0 border-b  
            ${props.errors.car_category ? "border-red-400" : "border-[#969696]"} w-full py-2 outline-none origin-bottom-left`}
          >
            {categories.map((category) => {
              return (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              );
            })}
            <option key={1} value={""}>
              Select a car category
            </option>
          </select> */}
          <Select
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null
            }}
            styles={customStyles}
            // value={{ value: '637770389d1969a524f3250e', label: 'Category 2' }}
            value={transformedCategory}

            onChange={(selectedOption) => {
              props.setFieldValue("car_category", selectedOption?.value);
            }}
            options={categories.map(category => ({ value: category._id, label: category.name }))}
            className={`bg-transparent text-white border-0 border-b  
  ${props.errors.car_category ? "border-red-400" : "border-[#969696]"} w-full py-2 outline-none origin-bottom-left`}
            placeholder="Select a car category"
          />
          <section className="object-contain flex justify-center items-center">
            <img className="w-72 h-72 object-contain" src={carImg} alt="vsd" />
          </section>
        </section>
        <section className="flex-1 space-y-4">
          <DriverDetailsCard loading={loading} driverDetails={attachDetails?.driver} error={props.errors.driver}/>
          <CarDetailsCard loading={loading}  carDetails={attachDetails?.car} error={props.errors.car}/>
        </section>
      </section>
    </>
  );
};

export default memo(ChooseCarDetails);
