import { useParams } from "react-router-dom";
import { Dollor, WhiteCar } from "../../../Assets/Home.svg";
import Feedback from "./Feedback";
import RatingsCardRow from "./RatingsCardRow";
import ReportFilter from "./ReportFilter";
import RidesAndTipsCard from "./RidesAndTipsCard";
import { useEffect, useState } from "react";
import { getDriverReports } from "../../../Services/CreateServices";
import { Loader } from "../../../Components/Button/FilledButton/FilledButton";
import { format } from 'date-fns';

const DriverReports = () => {
    const [DriverReportsData, setDriverReportsData] = useState<any>();
    const [filter, setFilter] = useState<string>("");

    const { id } = useParams();
    useEffect(() => {
        const getDriverReportsData = async () => {
            try {
                setDriverReportsData(null);
                const resp = await getDriverReports(id || "", filter);  // today , week, year filter and "" for all times
                setDriverReportsData(resp.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        id && getDriverReportsData();
    }, [id, filter]);

    const onPressFilter = (currentFilter: string) => {
        setFilter(currentFilter);
    }

    // calculate percentage raiting
    let fiveStarRaiting = DriverReportsData?.five_star_ratings;
    let fourStarRaiting = DriverReportsData?.four_star_ratings;
    let threeStarRaiting = DriverReportsData?.three_star_ratings;
    let twoStarRaiting = DriverReportsData?.two_star_ratings;
    let oneStarRaiting = DriverReportsData?.one_star_ratings;

    let totalRaiting = fiveStarRaiting + fourStarRaiting + threeStarRaiting + twoStarRaiting + oneStarRaiting;

    let fiveStarPercentageRaiting = ((fiveStarRaiting / totalRaiting) * 100).toFixed(0);
    let fourStarPercentageRaiting = ((fourStarRaiting / totalRaiting) * 100).toFixed(0);
    let threeStarPercentageRaiting = ((threeStarRaiting / totalRaiting) * 100).toFixed(0);
    let twoStarPercentageRaiting = ((twoStarRaiting / totalRaiting) * 100).toFixed(0);
    let oneStarPercentageRaiting = ((oneStarRaiting / totalRaiting) * 100).toFixed(0);

    return (
        <>
            <section className="my-12">

                <div className="flex justify-between">
                    <h3 className="lg:text-2xl text-base md:text-xl font-bold mb-10 text-[#EFEFEF]">
                        Reports
                    </h3>
                    <ReportFilter filter={filter} onPressFilter={onPressFilter} />
                </div>

                {
                    DriverReportsData ?
                        <>
                            <section className="grid lg:grid-cols-4 grid-cols-1 space-y-4 md:space-y-0 md: gap-11">
                                <RidesAndTipsCard
                                    type={"rides"}
                                    heading={"Number of rides"}
                                    icon={<WhiteCar />}
                                    value={DriverReportsData.number_of_rides}
                                />

                                <RidesAndTipsCard
                                    type={"tips"}
                                    heading={"Tips"}
                                    icon={<Dollor />}
                                    value={`$${DriverReportsData.total_tips}`}
                                    valueDescription={`(Based on ${DriverReportsData.number_of_rides} rides)`}
                                />

                                {/* Ratings card Starts */}
                                <section className='bg-[#1A1D1F] rounded-lg flex flex-col  items-center py-10 lg:col-span-2'>

                                    <span className='font-semibold font-hel text-[#FCFCFC]'>
                                        Ratings
                                    </span>

                                    <div className="w-full space-y-4 flex flex-col items-center mt-4">
                                        <RatingsCardRow star={5} guest={fiveStarPercentageRaiting} />
                                        <RatingsCardRow star={4} guest={fourStarPercentageRaiting} />
                                        <RatingsCardRow star={3} guest={threeStarPercentageRaiting} />
                                        <RatingsCardRow star={2} guest={twoStarPercentageRaiting} />
                                        <RatingsCardRow star={1} guest={oneStarPercentageRaiting} />
                                    </div>

                                </section>
                                {/* Ratings card ends */}
                            </section>

                            {/* Feebcak Section Starts */}
                            <section className=" bg-[#26292A] mt-10 rounded-xl p-4 md:p-8 lg:p-10">
                                <h3 className="lg:text-xl text-sm md:text-base font-bold text-[#EFEFEF]">
                                    Feedbacks
                                </h3>

                                {
                                    DriverReportsData.feedback.length > 0 ?

                                        DriverReportsData.feedback.map((item: any, index: any) => {
                                            return (
                                                <Feedback
                                                    guestName={item.guest_name}
                                                    star={item.ratings}
                                                    desc={item.comments}
                                                    tip={item.tips}
                                                    date={format(new Date(item.dateField), 'dd MMMM, yyyy')}
                                                />
                                            )
                                        })
                                        :
                                        <div className="pt-4 text-center">
                                            <span className=" text-gray-400 pt-10">No feedback available!</span>
                                        </div>
                                }

                            </section>
                            {/* Feebcak Section Ends */}
                        </>
                        :
                        <section
                            className="w-full h-full flex justify-center items-center border-dotted rounded-sm border-gray-400 ">
                            <Loader />
                        </section>
                }
            </section>
        </>
    );
};

export default DriverReports;
