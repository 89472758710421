
import {  Route, Routes } from "react-router-dom";
import Customers from "../Screens/Guest/GuestList";
import MainDashboard from "../Screens/Dashboard/MainDashboard";
import Driver from "../Screens/Driver/AddDriver/Driver";
import Home from "../Screens/Home/Home";
import AddCarDetails from "../Screens/CarScreens/AddCar/AddCarDetails";
import GuestList from "../Screens/Guest/GuestList";
import Booking from "../Screens/Booking/Booking";
import AdminPanel from "../Screens/AdminPanel/AdminPanel";
import AddContent from "../Screens/ContentManagement/AddContent";
import AddProfile from "../Screens/AdminPanel/AddProfile";
import CarList from "../Screens/CarScreens/CarDetails/CarList";
// import DriverListTable from "../Screens/Driver/AllDrivers/DriverListTable";
import Drivers from "../Screens/Driver/AllDrivers/Drivers";
import DriverReports from "../Screens/Driver/DriverReports/DriverReports";
import BookingDetails from "../Screens/Booking/BookingDetails";

type Props = {};

const ProtectedRoutes = (props: Props) => {
  return (
    <Routes>
      <Route path="" element={<MainDashboard />}>
        <Route>
          <Route path="" element={<Booking />} />
          <Route path="Booking" element={<Home />} />
          <Route path="Booking/edit/:id" element={<Home />} />
          {/* for booking details */}
          <Route path="Booking/bookingDetails/:id" element={<BookingDetails />} />
        </Route>
        <Route path="Drivers"  >
          <Route path="" element={<Drivers/>} />
          <Route path="Add" element={<Driver />}/>
          <Route path="Edit/:id" element={<Driver />}/>
          {/* for driver reports */}
          <Route path="driverReports/:id" element={<DriverReports />} />
        </Route>
        <Route path="Customers" element={<Customers />} />
        <Route path="GetAllCars">
          <Route path="" element={<CarList/>}/>
          <Route path="AddCarDetails">
          <Route path="" element={<AddCarDetails />} />
          <Route path=":id" element={<AddCarDetails />} />

        </Route>
        </Route>
      
        <Route path="GuestQue" element={<GuestList />} />
        <Route path="Booking" element={<Booking />} />
        <Route path="AdminPanel">
          <Route path="" element={<AdminPanel />} />
          <Route path="me/:id" element={<AddProfile />} />
          <Route path="addProfile" element={<AddProfile />} />
          <Route path="addProfile/:id" element={<AddProfile />} />
        </Route>
        <Route path="ContentMgm" element={<AddContent />} />
      </Route>
    </Routes>
  );
};

export default ProtectedRoutes;
