/* eslint-disable */
// @ts-nocheck
import React, { useRef, useState } from "react";
import { Column, useTable, useSortBy } from "react-table";
import { useNavigate } from "react-router-dom";
import { Bin, Edit, Up } from "../../Assets/Home.svg";
// import MiniAvatar from "../../Components/MiniAvatar";
import "./Table.css";
import TableOptionsCard from "./TableOptionsCard";
import { deleteBookedCar, guestPriority } from "../../Services/CreateServices";
import { showError, showSuccess } from "../../Components/Notifications";
import ConfirmationModal from "../../Components/ConfirmationModal";
import { BsArrowLeftShort ,BsArrowRightShort} from "react-icons/bs";
import TableDataLoader from "../../Components/TableDataLoader";
import EmptyStateData from "../../Components/EmptyStateData";


type Props = {

};
//@ts-ignore
const Table = ({ data, loading, fetchData,paginationCount,setPaginationCount,bookingdata }: any) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const deleteBookingRef = useRef()
  const onConfirm = async () => {
    setConfirmLoading(true)
    try {
      const resp = await deleteBookedCar(deleteBookingRef.current)
      console.log("confirmed", resp)
      if (resp.data.message)
        showSuccess(resp.data.message)
      else
        showSuccess("booking deleted successfully")
    } catch (error) {
      console.log(error)
      showError("something went wrong",
        "Error in deleting booking"
      )
    } finally {
      setConfirmLoading(false)
      onClose()
      fetchData()
    }


  }

  const onClose = () => {
    setOpenConfirmationModal(false)
  }
  const navigate = useNavigate()
  const MenuOptions = [
    {
      option: "Priority",
      icon: <Up />,
      handleClick: async (id: string) => {
        console.log("this is the pririty", id);
        try{
          // setConfirmLoading(true)
       
          const resp=await guestPriority(id) 
        console.log(resp,"this is priority result")
        fetchData()
        showSuccess("Booking has been successfully prioritized")
        }
        catch(err){
      console.log(err,"this is prority error")
      showError("something went wrong", 
      "Error in prioritizing"
      )
        }
      },
    },
    {
      option: "Edit",
      icon: <Edit />,
      handleClick: (id: string) => {
        console.log("this is the edit", id);
        navigate(`/Booking/edit/${id}`)
      },
    },
    {
      option: "Delete",
      icon: <Bin />,
      handleClick: (id: string) => {
        console.log("this is the delete", id);
        deleteBookingRef.current = id
        setOpenConfirmationModal(true)
      },
    },
  ];

  const columns: Array<
    Column<{
      guestName: string;
      guestRoomNo: string;
      formatted_pick_address: string;
      formatted_drop_address: string;
      driver: string;
    }>
  > = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "guestName", // accessor is the "key" in the data
        Cell: (props) => {
          return (
            <div className="text-left text-white font-hel font-light tracking-wide">
              {props.value}
            </div>
          );
        },
      },

      {
        Header: "Room",
        accessor: "guestRoomNo",
        Cell: (props) => {
          return (
            <div className="text-left text-white font-hel font-light tracking-wide">
              {props.value}
            </div>
          );
        },
      },

      {
        Header: "Pick Up Location",
        accessor: "formatted_pick_address",
        Cell: (props) => {
          return (
            <div className="text-left text-white font-hel font-light tracking-wide">
              {props.value}
            </div>
          );
        },
      },
      {
        Header: "Drop Location",
        accessor: "formatted_drop_address",
        Cell: (props) => {
          return (
            <div className="text-left text-white font-hel font-light tracking-wide">
              {props.value}
            </div>
          );
        },
      },
      {
        Header: "Driver Details",
        accessor: "driver",
        Cell: (props) => {
          return (
            <TableOptionsCard options={MenuOptions} id={props.cell.row.original.id} value={props.value} />
          );
        },
      },
    ],
    []
  );

  const { getTableProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

    function increasePaginationCount()
    {
    setPaginationCount(paginationCount+1)
    
    }
    
    function decreasePaginationCount()
    {
    setPaginationCount(paginationCount-1)
    }

    const leftButtonStyle=()=>{
      if(paginationCount==1 || data.length==0)
      {
       return {color:"#808080"}
      }
      else{
        return {color:"white"}
      }
    }
    const RightButtonStyle=()=>{

      if(paginationCount*10>=bookingdata.length)
      {
        return {color:"#808080"}

      }
      else{
        return {color:"white"}
      }
    }


  return (
    <>
      <section>
        <ConfirmationModal
          onClose={onClose}
          onConfrim={onConfirm}
          loading={confirmLoading}
          open={openConfirmationModal}
        />
        <h1 className="lg:text-4xl text-2xl font-bold mb-10 text-[#EFEFEF]">
          Guest Queue
        </h1>
      </section>
      <div className="bg-[#1A1D1F] overflow-auto p-7 2xl:max-w-6xl rounded-lg">
        <table
          {...getTableProps()}
          className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <thead className="text-xs text-white uppercase bg-[#26292A]">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index, columns) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`px-6 ${index === columns.length - 1 ? "text-center" : "text-left"
                      }  py-5 font-medium whitespace-nowrap`}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tr className="p-10">
            <th className="p-2"></th>
          </tr>
          {
          !loading && rows.length > 0 && <tbody>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="border-t  border-[#6F767E] border-opacity-50"
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="pl-6  py-4 m-0 ">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
}
        </table>
        {
        loading && <div className="w-full  h-44 ">
          <TableDataLoader/>
          </div> 
        }
           {
          
          !loading  &&  rows.length < 1 && 
          <div className="w-full h-44 ">

          <EmptyStateData/>
          </div>
         
        }     
        <div style={{color:"white",display:'flex',gap:'30px',justifyContent:'center'}}>
          <button style={leftButtonStyle()}  disabled={paginationCount==1 || data.length==0} onClick={decreasePaginationCount}><BsArrowLeftShort/></button>
          <button style={RightButtonStyle()} disabled={paginationCount*10>=bookingdata.length}  onClick={increasePaginationCount}><BsArrowRightShort/></button>
         </div>
      </div>
    </>
  );
};

export default Table;
