/* eslint-disable */ 
import React, { useRef, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { Navigate, useNavigate } from "react-router-dom";
import { Column, useSortBy, useTable } from "react-table";
import { Bin, Edit } from "../../../Assets/Home.svg";
import FilledButton from "../../../Components/Button/FilledButton/FilledButton";
import ConfirmationModal from "../../../Components/ConfirmationModal";
import EmptyStateData from "../../../Components/EmptyStateData";
import MiniAvatar from "../../../Components/MiniAvatar";
import TableDataLoader from "../../../Components/TableDataLoader";
import { deleteCarbyId, getAllCars } from "../../../Services/CreateServices";
import TableOptionsCard from "../../Booking/TableOptionsCard";
// import BookingFilter from "../../Booking/BookingFilter";

type Props = {
  loading?: boolean;
  getAllBookingsData:()=>{};
  data: {
    _id?: string;
    car_number: string;
    car_model_no: string;
    car_brand_name: string;
    car_category: {
      name : string
    };
    car_colour: string;
    car_image: string;
  }[];
};




const CarListTable = ({ loading, data,getAllBookingsData }: Props) => {
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const carid = useRef<string>()

  const ToggleModal = () => {
    setOpen(prev  => !prev)
}

const ToggleConfirmationModal = () => {
  setOpenConfirmation(prev => !prev)
}
    const navigate = useNavigate()

    const deleteDriver = async () => {
      console.log("delete driver")
      try {
        const resp = await deleteCarbyId(carid.current ?? "")
        console.log(resp)
        ToggleConfirmationModal()
        getAllBookingsData()
      } catch (error) {
        console.log(error)
      }
    }



  const columns: Array<
    Column<{
      car_model_no: string;
      car_brand_name: string;
      car_category: {
        name : string
      };
      car_number: string;
      car_colour: string;
      _id?: string;
      car_image?: string;
    }>
  > = React.useMemo(
    () => [
      {
        Header: "Model Number",
        accessor: "car_model_no", // accessor is the "key" in the data
        Cell: (props) => {
          return (
            <div className="text-left h-12 text-white font-hel font-light tracking-wide flex alignCenter">
              <MiniAvatar url={props.cell.row.original.car_image} />
              {props.value}
            </div>
          );
        },
      },
      {
        Header: "Car Brand",
        accessor: "car_brand_name",
        Cell: (props) => {
          return (
            <div className="text-left text-white font-hel font-light tracking-wide">
              {props.value}
            </div>
          );
        },
      },

      {
        Header: "Type & Category",
        accessor: 'car_category',
        Cell: (props) => {
          return <div>
              {props.cell.row.original.car_category.name}
          </div>;
        },
      },
      {
        Header: "Licence plate",
        accessor: "car_number",
        Cell: (props) => {
          return (
            <div className="text-left text-white font-hel font-light tracking-wide">
              {props.value}
            </div>
          );
        },
      },
      {
        Header: "Car Color",
        accessor: "car_colour",
        Cell: (props) => {
          console.log(props)
          return (
            <TableOptionsCard 
            options={MenuOptions}
      
            id={props.cell.row.original._id || ""}
            value={props.value}
            />
            
          );
        },
      },
      //   {
      //     Header: "Car Details",
      //     accessor: "car.car_brand_name",
      //     Cell: (props) => {
      //       return <div></div>;
      //     },
      //   },
    ],
    []
  );
  const MenuOptions = [
    
    {
      option: "Edit",
      icon: <Edit />,
      handleClick: (id: string) => {
        // console.log("this is the edit", id);
        navigate(`AddCarDetails/${id}`)
      },
    },
    {
      option: "Delete",
      icon: <Bin/>,
      handleClick:async (id: string) => {
        carid.current=id
        setOpenConfirmation(true)

      },
    },
  ];


  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <>
      <section role="car-list-table">
      <ConfirmationModal
          open={openConfirmation}
          onClose={ToggleConfirmationModal}
          onConfrim={deleteDriver}
          onOpen={() => {}}
          title=" Are you sure you want to delete this driver"
          loading={tableLoading}
          subtitle=""
        />
        <div
          className="flex"
          style={{ justifyContent: "space-between", alignItems: "flex-start" }}
        >
          <h3 className="lg:text-2xl text-1xl font-bold mb-10 text-[#EFEFEF]">
            All Cars
          </h3>
          <div onClick={() => {
          navigate("/GetAllCars/AddCarDetails")
        }}>
          <FilledButton
            children={"New Car"}
            loading={false}
            style={{
              paddingLeft: "1rem",
              paddingRight: "1.5rem",
              marginTop: 0,
              borderRadius : "0.8rem"
            }}
            icon={<BiPlus size={20} className="mr-2" />}
          />
        </div>
        </div>
      </section>
      <div className="bg-[#1A1D1F] carList  overflow-auto p-7 2xl:max-w-6xl rounded-lg">
        <table
          {...getTableProps()}
          className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <thead className="text-xs text-white uppercase bg-[#26292A]">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index, columns) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`px-6 ${
                      index == columns.length ? "text-center" : "text-left"
                    }  py-5 font-medium whitespace-nowrap`}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tr className="p-10">
            <th className="p-2"></th>
          </tr>
          {!loading && rows.length > 0 && (
            <tbody>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="border-t  border-[#6F767E] border-opacity-50"
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="pl-6  py-4 m-0 "
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {loading && (
          <div className="w-full  h-44 ">
            <TableDataLoader />
          </div>
        )}
        {!loading && rows.length < 1 && (
          <div className="w-full h-44 ">
            <EmptyStateData />
          </div>
        )}
      </div>
    </>
  );
};

export default CarListTable;
