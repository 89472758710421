
/* eslint-disable */
import { Menu, MenuDivider, MenuItem } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { BlueVector } from '../../../Assets/Home.svg';

const MenuOptions = [{
    option: "Today",
    id: "today"
},
{
    option: "This week",
    id: "week"
},
{
    option: "This year",
    id: "year"
},
]

interface ReportFilterPropType {
    filter: string,
    onPressFilter : (filter:string) => void;
}

const ReportFilter = (props: ReportFilterPropType) => {
    return (
        <section className='flex mr-3'
            style={{ justifyContent: 'space-between', alignItems: 'center', maxWidth: 150 }}>
            <div className=' ml-28 pl-1'>
                <Menu className="bg-black" menuStyle={{
                    backgroundColor: '#26292A',
                    color: "white"
                }} menuButton={<div className='filter'>

                    <span className=" w-10 h-10 bg-[#2A85FF] bg-opacity-30 rounded-md flex justify-center  items-center">
                        <BlueVector />
                    </span>

                </div>}>
                    {
                        MenuOptions.map((option, index, options) => {
                            return <div key={index * 23}

                            >
                                <MenuItem id='filterMenuItem'
                                    onClick={() => { props.onPressFilter(option.id) }}
                                    className={(events) => `flex space-x-3 ${events.hover ? `text-black` : `text-white`} 
                         ${props.filter == option.id ? 'bg-white text-black' : 'bg-transparent text-white'}`} >
                                    <span >{option.option}</span>
                                </MenuItem>

                                <MenuDivider
                                    style={{ backgroundColor: '#6F767E73', marginRight: 20, marginLeft: 20, opacity: 0.8 }}
                                    className={`bg-white ${index == options.length - 1 && 'hidden'}`}
                                />
                            </div>
                        })
                    }

                </Menu>
            </div>
        </section>
    )
}

export default ReportFilter;