import { TiWarningOutline } from "react-icons/ti";

type Props = {
  placeholder?: string;
  label?: string;
  error?: string;
  value?: string;
  onChange?: any;
  type?: string;
  large?: Boolean;
  name?: string;
  touched?: boolean;
};

const Input = (props: Props) => {
  const errorStyles =
    props.error && props.touched ? "text-red-400" : "text-white";
  return (
    <div className="flex flex-col items-start w-full relative">
      <label className="mb-0 text-white text-base">{props.label}</label>
      <input
        autoComplete={"off"}
        name={props.name}
        className={`
          border-0 
          border-b
          focus:border-white
          border-[#969696]
          bg-transparent
          ${errorStyles}
          w-full 
          ${props.large ? "" : "text-lg"}
          outline-none py-2
         placeholder:text-gray-600`}
        type={props.type}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
      />
      {props.error && props.touched && (
        <TiWarningOutline
          className=" absolute right-3 top-1/2 text-red-400 "
          size={20}
        />
      )}
    </div>
  );
};

export default Input;
