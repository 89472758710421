import { FilledStar } from "../../../Assets/Home.svg";

interface FeedbackInterface {
    guestName: string
    star: number
    desc: string
    tip: string
    date: string
}

const Feedback = (props: FeedbackInterface) => {
    return (
        <section className=" mt-6 bg-[#323435]  rounded-xl p-4 md:p-6 lg:p-8 mb-6">
            <div className=" grid md:grid-cols-12 gap-2 md:gap-4 lg:gap-6 ">
                <div className="flex md:flex-col justify-between md:justify-start md:col-span-2">
                    <span className="text-white capitalize">{props.guestName}</span>
                    <div className="flex items-center gap-1 justify-end md:justify-start w-32">
                        <FilledStar />
                        <span className="text-[#A9A9A9] text-xs">{props.star} stars</span>
                    </div>
                </div>
                <div className="justify-start md:col-span-10">
                    {
                        props.desc ?
                            <p className="text-white text-sm">{props.desc}</p> :
                            <p className="text-[#A9A9A9] text-sm">Not available!</p>
                    }

                </div>
            </div>

            <div className="flex mt-2 justify-between">
                <span className="text-white font-normal">Tip: ${props.tip || 0}</span>
                <span className="text-[#A9A9A9] text-sm">Rode on {props.date}</span>
            </div>
        </section>
    )
}

export default Feedback;