import { Loader } from "./Button/FilledButton/FilledButton"

type Props = {
    url ?: string,
    width ?: string,
    height ?: string,
    error ?: string,
    touched ?: boolean,
    loading ?: boolean
}
const dummy = 'https://cdn1.crystalcommerce.com/themes/clients/threekingsloot/assets/img/ui/no-image-available.png?1415598833'
const Avatar = (props: Props) => {
  return (
    <div className={`${props.width ? props.width : "w-16"}  ${props.height ? props.height : "h-16"} rounded-full border-2 ${props.error && props.touched ? "border-red-500" : "border-white"} p-1 flex justify-center ${props.loading && "items-center"}`}>
       {
         props.loading ? <Loader/> :  <img
         className='w-full rounded-full object-cover'
         src={`${props.url ? props.url : dummy}`}
         alt='yyg'
         />
       }
    </div>
  )
}

export default Avatar