import { Form, Formik } from "formik";
import FilledButton from "../../../Components/Button/FilledButton/FilledButton";
import Input from "../../../Components/Input/Input";
import { showSuccess } from "../../../Components/Notifications";
import { forgotPasswordSchema } from "../../../Configs/validation";
import { useUserAuth } from "../../../Context/UserAuthContext";

type Props = {};

interface Values {
  email: string;
}

const ForgotPasswordForm = (props: Props) => {
  const { ResetPassword } = useUserAuth();
  return (
    <section className="space-y-8 md:w-10/12 w-full">
      <section>
        <h1 className="font-semibold md:text-3xl text-white">
          Forgot Password
        </h1>
        <h3 className="text-xs text-white mt-0">Enter your email id</h3>
      </section>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={forgotPasswordSchema}
        onSubmit={async (values: Values) => {
         
          try {
            const user = await ResetPassword(values.email);
            console.table(user);
          } catch (error) {
            showSuccess("Password Reset Link Sent", "Check Your Mail")
            console.log(error);
          } 
        }}
      >
        {({ isSubmitting, handleChange, errors, values, touched }) => {
          return (
            <Form>
              <Input name="email" value={values.email}
              touched={touched.email}
              error={errors.email} label="Email address" onChange={handleChange} placeholder="xyz@example.com" />

              <FilledButton
              loading={isSubmitting}
             
                type={"submit"}
              >
                Send Reset Link
              </FilledButton>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};

export default ForgotPasswordForm;
