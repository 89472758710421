import { useState } from "react";
import { Link } from "react-router-dom";
import FilledButton from "../../../Components/Button/FilledButton/FilledButton";
import Input from "../../../Components/Input/Input";
import { Form, Formik } from "formik";
import { loginSchema } from "../../../Configs/validation";
import { useUserAuth } from "../../../Context/UserAuthContext";
import { showError } from "../../../Components/Notifications";
// import Logo from "../../../Assets/logo.png";
import { userSignIn } from "../../../Services/AuthServices";
type Props = {};

interface Values {
  email: string;
  password: string;
}

const LoginForm = (props: Props) => {
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const { updateUser } = useUserAuth();

  return (
    <section className="space-y-8 md:w-10/12 w-full">
      <section>
        <h1 className="font-semibold font-hel sm:text-xl md:text-2xl lg:text-3xl text-white">
          Welcome to Four Seasons
        </h1>
        <h3 className="text-xs text-white mt-0">
          Please Enter your email and password to continue
        </h3>
      </section>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={loginSchema}
        onSubmit={async (values: Values, { setErrors, setStatus }) => {
          try {
            const resp = await userSignIn(values.email, values.password);
            console.table(resp.data);

            updateUser(resp.data.data, rememberMe);
          } catch (error) {
            const { message } = error as Error; // I removed : { message: string } because it should be infered
            console.log("ssd", message);
            showError("login error","check username and password")
            setErrors({
              email: "",
              password: "",
            });
          } finally {
        
          }
        }}
      >
        
        {({ isSubmitting, handleChange, errors, values, touched }) => {
         console.log(values)
         return (
            <Form className="space-y-8" autoComplete="off">
              <Input
                onChange={handleChange}
                name="email"
                touched={touched.email}
                error={errors.email}
                value={values.email}
                label="Email address"
                placeholder="xyz@example.com"
              />

              <Input
                type="password"
                name="password"
                label="Password"
                touched={touched.password}
                error={errors.password}
                value={values.password}
                onChange={handleChange}
                placeholder="enter your password"
              />

              <section className="flex justify-between items-center">
                <section className="flex items-center space-x-2">
                  <input
                    type={"checkbox"}
                    onChange={(event) => {
                      setRememberMe(event.target.checked);
                    }}
                    className="leading-loose h-4 w-4"
                  />
                  <label className="text-white">Remember Password</label>
                </section>
                <section>
                  <Link
                    to={"ForgotPassword"}
                    className="text-primary-blue font-semibold"
                  >
                    Forgot Password
                  </Link>
                </section>
              </section>

              <FilledButton
              type={"submit"}
                loading={isSubmitting}
                disabled={isSubmitting}
                title="Login"
              >
                Login
              </FilledButton>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};

export default LoginForm;
