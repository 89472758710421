import React from "react";
import { Loader } from "../Button/FilledButton/FilledButton";

type Props = {
  carDetails : any
  loading: boolean
  error : any
};

const CarDetailsCard = ({ carDetails, loading, error }: Props) => {
console.log("meanwhile", error)
  return (
    <section className={`${error ? "border-[#1A1D1F]" : "border-[#1A1D1F]"} border text-white relative p-7 bg-[#1A1D1F] rounded-lg flex flex-col space-y-7`}>
      <div className={`absolute z-20 top-0 left-0 h-full w-full rounded-lg
        ${loading ? "" : "hidden"}
         bg-[#1A1D1F] flex justify-center items-center`}>
        <Loader/>
        </div>
      <section className="flex items-center space-x-5 text-lg">
        <span>Brand</span> <span>{carDetails?.car_brand_name ?? "  "}</span>
      </section>
      <section className="flex items-center space-x-5 text-lg">
        <span>Model</span> <span>{carDetails?.car_model_no ?? "  "}</span>
      </section>
      <section className="flex items-center space-x-5 text-lg">
        <span>Colour</span> <span>{carDetails?.car_colour ?? "  "}</span>
      </section>
    </section>
  );
};

export default CarDetailsCard;
