import { FiCamera } from "react-icons/fi";
import Avatar from "../../../Components/Avatar";
import FilledButton from "../../../Components/Button/FilledButton/FilledButton";
import Input from "../../../Components/Input/Input";
import adv from "../../../Assets/ad.png";
import { Form, Formik } from "formik";
import { addCarDetailsSchema } from "../../../Configs/validation";
import { showError, showSuccess } from "../../../Components/Notifications";
import {  useNavigate, useParams } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import { getMinifiedURL } from "../../../Services/UploadImages";
import { addCarDetails, getAllCars, getCarCategories, updateCarDetails } from "../../../Services/CreateServices";
import LocalStorageService from "../../../Services/LocalStorageService";


type Props = {};

interface Values {
  car_model_no: string;
  car_image: string;
  car_category: string ;
  car_brand_name: string;
  car_number: string;
  car_colour: string;
}

export interface ICategories {
  createdAt: string;
  name: string;
  updatedAt: string;
  __v: 0;
  _id: string;
}

const AddCarDetails = (props: Props) => {
  const [categories, setCategories] = useState<ICategories[]>([]);
  const [imageLoading, setImageLoading] = useState(false)
  const [carDetails,setCarDetails]=useState<Values>()
  const navigate=useNavigate()
  const { id } = useParams();
  useEffect(() => {
    getCarCategoriesData();
  }, [id]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    console.log("driver id", id);
    id && getCarDetails();
    // eslint-disable-next-line
  }, [id]);
 
const getCarDetails= async ()=>{
if(id)
{
  try{
  const Details=await getAllCars()
console.log(Details?.data.data.filter((e:any)=>{return e._id===id}),"edit driver details")
const requiredCar=Details?.data?.data?.filter((e:any)=>{return e._id===id})[0]
setCarDetails({
  car_model_no: requiredCar.car_model_no,
  car_image:requiredCar.car_image,
  car_category:requiredCar.car_category._id,
  car_brand_name:requiredCar.car_brand_name,
  car_number: requiredCar.car_number,
  car_colour:requiredCar.car_colour
})
}
catch(err)
{
  console.log(err,"edit driver error")
}
  }
}
// console.log(carDetails,"cardetails")


  const getCarCategoriesData = async () => {
    try {
      const categoryResp = await getCarCategories();
      console.log(categoryResp.data.data);
      setCategories(categoryResp.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      // if (!id) return;
      //  getCarDetails();
    }
  };



  
  return (
    <main className="my-12">
      <section className="">
        <h1 className="text-4xl font-bold mb-10 text-white">Car Details</h1>
      </section>
      <Formik
      enableReinitialize
        initialValues={{
          
          car_number:carDetails?.car_number ?? "",
          car_model_no:carDetails?.car_model_no ?? "",
          car_brand_name:carDetails?.car_brand_name  ?? "",
          car_category:carDetails?.car_category ?? "",
          car_colour:carDetails?.car_colour ?? "",
          car_image: carDetails?.car_image ?? "",
        }}
        validationSchema={addCarDetailsSchema}
        onSubmit={async (
          values: Values,
          { setErrors, setStatus, resetForm }
        ) => {
          console.log("csds");
          try {
            if(id)
            {
           const resp=await updateCarDetails(id,JSON.stringify({...values}))
           console.log(resp);
           showSuccess("Car Details Edited Successfully", "Check car List");
            }
           else{
             const resp = await addCarDetails({...values,hotel:LocalStorageService.getParsedData('user').hotel});
            console.log(resp);
            showSuccess("Car Details Added Successfully", "Check car List");
           }
            resetForm();
            setTimeout(()=>{
             navigate("/GetAllCars")
            },2000)
          } catch (error) {
            const { message } = error as Error; // I removed : { message: string } because it should be infered
            console.log("ssd", message);
            showError(message, "");
          }
        }}
      >
        {({
          isSubmitting,
          handleChange,
          errors,
          values,
          touched,
          resetForm,
          setFieldValue,
          initialValues
        }) => {
          console.log(values,"checking initial values");
          return (
            <Form autoComplete="off">
              <section className="bg-[#1A1D1F] rounded-md flex flex-col space-y-10  pt-0 p-10 relative">
                <img
                  src={adv}
                  alt="hj"
                  className="w-36 absolute right-0 -top-6"
                />
                <section className="flex space-x-20 items-center">
                  <div className="bg-transparent relative  rounded-full">
                    <Avatar width="w-28" height="h-28"
                    loading={imageLoading}
                    url={values.car_image}
                    error={errors.car_image}
                    touched={touched.car_image}
                    />
                    <label className="cursor-pointer mt-6 absolute bottom-1 bg-[#2A85FF] p-2 rounded-full right-0">
                      <FiCamera className="text-white text-sm" />
                      <input
                        type="file"
                        onChange={async (evt) => {
                          setImageLoading(true)
                         try {
                          let files = evt.target.files;
                          if (!files?.[0]) return;
                          const url = await getMinifiedURL(files[0]);
                          setFieldValue("car_image", url);
                         } catch (error) {
                           console.log(error)
                         } finally {
                            setImageLoading(false)
                         }
                        }}
                        className="hidden"
                      />
                    </label>
                  </div>
                </section>

                <section className="flex md:space-x-20 md:flex-nowrap space-y-7 md:space-y-0 flex-wrap  items-center">
                  <Input
                    label="Car Brand"
                    type="text"
                    error={errors.car_brand_name}
                    touched={touched.car_brand_name}
                    onChange={handleChange}
                    placeholder="Range Rover"
                    name="car_brand_name"
                    value={values.car_brand_name}
                  />
                    <section className="w-full">
                    <span className="text-white">Category</span>
                    <select
                      defaultValue={values.car_category  }
                      value={values.car_category }
                      
                      onChange={(evt) => {
                        setFieldValue("car_category", evt.target.value);
                        console.log(evt.target.value);
                      }}
                      className={`bg-transparent text-white border-0 border-b  ${errors.car_category ? "border-red-500" : "border-[#969696]"} w-full py-2 outline-none origin-bottom-left`}
                    >
                      {categories.map((category) => {
                        return (
                          <option key={category._id} value={category._id}>{category.name}</option>
                        );
                      })}
                      <option key={1} value={""}>Select a car category</option>
                    </select>
                  </section>
                </section>
         
                <section className="flex md:space-x-20 md:flex-nowrap space-y-7 md:space-y-0 flex-wrap  items-center">
                <Input
                    label="Model Number"
                    error={errors.car_model_no}
                    placeholder="ex : ZRE45D1"
                    onChange={handleChange}
                    name="car_model_no"
                    touched={touched.car_model_no}
                    value={values.car_model_no}
                  />
                
                <Input
                    label="Licence plate"
                    type="text"
                    error={errors.car_number}
                    touched={touched.car_number}
                    onChange={handleChange}
                    placeholder="6PYV308"
                    name="car_number"
                    value={values.car_number}
                  />
                  {/* <Input label="Type & Category" type="text" onChange={handleChange} placeholder="ex: Phase21 " name="car_category" value={values.car_category} /> */}
                 
                </section>

                
                <section className="flex md:space-x-20 md:flex-nowrap  md:space-y-0 flex-wrap  items-center">
                  <section className="flex-1">
                    <Input
                      type="text"
                      label="Car Colour"
                      error={errors.car_colour}
                      touched={touched.car_colour}
                      onChange={handleChange}
                      placeholder="Premium Matte"
                      name="car_colour"
                      value={values.car_colour}
                    />
                  </section>
                  <section className="md:flex-1"></section>
                </section>
              </section>
              <section className="flex space-x-10 mt-16 mb-20">
                <FilledButton type="button" handleClick={resetForm} filled>
                  Clear
                </FilledButton>
                <FilledButton type="submit" loading={isSubmitting}>
                  Confirm
                </FilledButton>
              </section>
            </Form>
          );
        }}
      </Formik>
    </main>
  );
};

export default AddCarDetails;
