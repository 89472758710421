import { GoogleMap, OverlayView, useJsApiLoader, Marker } from "@react-google-maps/api";
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Mapicon } from "../../Components/MapIcons/Mapicon";
import { showError } from "../../Components/Notifications";
import TableDataLoader from "../../Components/TableDataLoader";
import { getLocationByHotel } from "../../Services/CreateServices";
// import io from "socket.io-client";

// const socket= io("http://api2.titanurban.com", {
//   transports: ["websocket"],
// });

const mapContainerStyle = {
  height: "459px",
  width: "100%",
};

const getPixelPositionOffset = (width: number, height: number) => ({
  x: -(width / 2),
  y: -(height / 2),
});

var Defaultcentervalue = {
  lat: 34.0522,
  lng: -118.2437,
};

var currentLocationValue = {
  lat: 34.0522,
  lng: -118.2437,
};

export const DriverlocationMap = () => {
  const mapRef = useRef<any>(null);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyC2irz5gZIDE4yNqTqqpBvym6Jzvov_R6k",
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  // console.log(isLoaded, "checking map");

  const gethotellocation = async () => {
    try {
      let result = await getLocationByHotel();
      // console.log(result.data.data, "this is hotel oclation");
      setData(result.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useLayoutEffect(() => {
    gethotellocation();
  }, []);

  const getCurrentPosition = async () => {
     await navigator.geolocation.getCurrentPosition(function (position) {
        Defaultcentervalue = { 
              lat: position.coords.latitude, 
              lng: position.coords.longitude
            }
        currentLocationValue = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        setLoading(false);
    })
  }

  useEffect(() => {
    getCurrentPosition();
    let interval = setInterval(() => {
      gethotellocation();
    }, 5000);
    // note: for every 5 seconds request will be made for driver location
    return () => clearInterval(interval);
  }, []);

  // testtt

  // useEffect(() => {
  //   // console.log(socket, "this is outside socket");
  //   socket.on("connection", () => {
  //     console.log("connected");
  //   });

  //   socket.on("get-socketid", (data) => {
  //     console.log("connected", data);
  //   });

  //   socket.emit("get-current-location", {});
  //   socket.on("get-location", (data) => {
  //     console.log(data);
  //   });

  //   const data = {
  //     driverId: "6319b6c0865290bad27baed1",
  //   };
  //   socket.emit("get-driver-current-location", data);
  //   socket.on("get-driver-location", (data) => {
  //     console.log(data);
  //   });

  //   socket.on("get-location", (data) => {
  //     console.log("get-location", data);
  //   });
  //   // socket.emit("sending", { name: "suraj", age: 23 })

  //   socket.on("disconnect", () => {
  //     console.log("disconnected");
  //   });

  //   return () => {
  //     socket.off("connect");
  //     socket.off("disconnect");
  //     socket.off("get-socketid");
  //     socket.off("get-driver-location");
  //     socket.off("get-current-location");
  //     socket.off("get-location");
  //   };
  // }, []);

  // testtt

  // console.log(data[0]["location"]["coordinates"][0], "mydata printed");

  if (loadError) {
    showError(
      "Googlemap error",
      "Error Has been occured while fetching the map details."
    );
  }

  // resposible for getting default center values while changing the center

  function handleLoad(map: any) {
    mapRef.current = map;
  }

  function handleCenterChanged() {
    if (!mapRef.current) return;
    const newPos = mapRef.current.getCenter().toJSON();
    Defaultcentervalue = newPos;
    // console.log(mapRef.current?.getCenter().toJSON(),'maapp')
  }

  return isLoaded && !loadError && !loading ? (
    <GoogleMap
      id="overlay-view-example"
      mapContainerStyle={mapContainerStyle}
      zoom={11}
      center={Defaultcentervalue}
      onCenterChanged={handleCenterChanged}
      onLoad={handleLoad}
      // center={{
      //   lat: data[0] ? data[0]["location"]["coordinates"][0] : 34.071602,
      //   lng: data[0] ? data[0]["location"]["coordinates"][1] : -116.546205,
      // }}
      options={{ clickableIcons: false }}
      mapTypeId="map"
    >
      <Marker position={{
            lat: currentLocationValue.lat,
            lng: currentLocationValue.lng,
          }}/>
      {data.map((e: any) => (
        <OverlayView
          position={{
            lat: e?.location?.coordinates[0],
            lng: e?.location?.coordinates[1],
          }}
          // position={{ lat: 34.071602, lng: -116.546205 }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          <Mapicon
            imageurl={e?.driver?.driver_image}
            name={e?.driver?.driver_name ?? "Driver Name"}
          />
        </OverlayView>
      ))}
    </GoogleMap>
  ) : (
    <TableDataLoader />
  );
};
