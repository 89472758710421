
/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import { useUserAuth } from "../Context/UserAuthContext";
import LocalStorageService from "../Services/LocalStorageService";

type Props = {};

const Authentication = (props: Props) => {
  const [authentication, setAuthentication] = useState<boolean>(true);
  const { user, logOut } = useUserAuth();
  useLayoutEffect(() => {
    console.log("called",user, authentication)
    user ? setAuthentication(true) : setAuthentication(false); 
  }, [user]);
 useEffect(() => {
    const now = Date.now()
    const tokenTimeLimit = LocalStorageService.getRefreshTokenTime()
    console.log("blogout out", tokenTimeLimit-now)
    if(tokenTimeLimit && tokenTimeLimit < now) {
      console.log("logout out", tokenTimeLimit)
      logOut()
    }
   });

console.log("cdd",authentication)
  return (
    <Routes>
       <Route
        path="/*"
        element={
          authentication ? (
            <ProtectedRoutes />
          ) : (
            <Navigate to="/Login" replace />
           
          )
        }
      />
      <Route
        path="/Login/*"
        element={
          !authentication ? <PublicRoutes /> : <Navigate to="/" replace />
        }
      />
     
    </Routes>
  );
};

export default Authentication;
