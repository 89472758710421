// Import the functions you need from the SDKs you need
import   { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from 'firebase/auth'
import { getStorage, ref, uploadBytes} from 'firebase/storage'


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC1qLzpWwRSTKYkQn6NHacoRAfKwUIa8kY",
  authDomain: "titan-urban.firebaseapp.com",
  databaseURL: "https://titan-urban-default-rtdb.firebaseio.com",
  projectId: "titan-urban",
  storageBucket: "titan-urban.appspot.com",
  messagingSenderId: "144261618689",
  appId: "1:144261618689:web:80e174d61517a98c277400"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const storage = getStorage(app)

export {  ref,  uploadBytes, storage}
export const auth = getAuth(app)


