/* eslint-disable */ 
import React, { useEffect, useState } from 'react'
import { getAllCars } from '../../../Services/CreateServices'
import CarListTable from './CarListTable'

type Props = {}

const CarList = (props: Props) => {
    const [loading, setLoading] = useState(false)
    const [allCars, setAllCars] = useState([])

    useEffect(() => {
        getAllBookingsData()
    },[])

    const getAllBookingsData = async () => {
        setLoading(true)
        try {
          const { data } = await getAllCars()
          console.log("all cars",data.data)
          setAllCars(data.data)
        } catch (error) {
            console.log(error)
        }finally{
          setLoading(false)
        }
      }
  return (
    <section role="car-list-container" className='my-12'>
        <CarListTable data={allCars} loading={loading} getAllBookingsData={getAllBookingsData}  />
    </section>
  )
}

export default CarList