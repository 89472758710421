/* eslint-disable */
// @ts-nocheck
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FiCamera } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import Avatar from "../../Components/Avatar";
import FilledButton from "../../Components/Button/FilledButton/FilledButton";
import Input from "../../Components/Input/Input";
import { showError, showSuccess } from "../../Components/Notifications";
import { addProfileSchema } from "../../Configs/validation";
import { useUserAuth } from "../../Context/UserAuthContext";
import {
  adminSignup,
  getAdminByID,
  updateAdmin,
} from "../../Services/CreateServices";
import { getMinifiedURL } from "../../Services/UploadImages";

// type Props = {
//   hotel: string;
//   name: string;
//   profile_img: string;
//   email: string;
//   shift_time: string;
//   password: string;
//   fcm_token: string;
// };

const AddProfile = () => {
  const { id } = useParams();
  const { user, setUserDetails } = useUserAuth();
  const navigate = useNavigate()
  const [imageLoading, setImageLoading] = useState(false)
  const [adminDetails, setAdminDetails] = useState<any>({
    name: "",
    profile_img: "",
    email: "",
    shift_time: "",
    password: "",
    fcm_token: "",
  });
  console.log(id);
  useEffect(() => {
    id && fetchAdminProfile();
  }, [id]);

  const fetchAdminProfile = async () => {
    try {
      const resp = await getAdminByID(id!);
      console.log("resp of admin prifle", resp);
      setAdminDetails(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const addAdminProfile = async (resetForm: () => void, values: any) => {
    try {
      const { data} = !id
        ? await adminSignup(values)
        : await updateAdmin(id!, values);
      console.log({ data}, user?.hotel);
      id
        ? showSuccess("Admin Updated Successfully", "Check Admin List")
        : showSuccess("Admin Profile Created Successfully", "Check Admin List");
      // resetForm();
      setTimeout(()=>{
        navigate("/AdminPanel")
      },2000)
       user.id == id && setUserDetails(data.data)
       
    } catch (error) {
      const { message } = error as Error; // I removed : { message: string } because it should be infered
      console.log("ssd", message);
      showError(message, "something is wrong ");
    } 
  };

  return (
    <>
      <section className="mt-12">
        <h1 className="text-4xl font-bold mb-10 text-white">
          {id ? [
            user?.id == id ? "Me" : "Update Admin" 
          ]: "Add New Admin"}
        </h1>
      </section>
      <Formik
        enableReinitialize
        initialValues={{
          hotel: user?.hotel,
          name: adminDetails?.name,
          profile_img: adminDetails?.profile_img,
          email: adminDetails?.email,
          shift_time: adminDetails?.shift_time,
          password: adminDetails?.password,
          fcm_token: "asfgdffnkdjsn k234545",
        }}
        validationSchema={addProfileSchema}
        onSubmit={async (values: any, { setErrors, setStatus, resetForm }) => {
          id && delete values.password
          console.log("csds",values);
          await addAdminProfile(resetForm, values);
        }}
      >
        {({
          isSubmitting,
          handleChange,
          errors,
          values,
          touched,
          resetForm,
          setTouched,
          setFieldValue,
        }) => {
          return (
            <Form autoComplete="off">
              <section className="bg-[#1A1D1F] rounded-md flex flex-col space-y-10 p-10">
                <section className="flex space-x-20 items-center">
                  <div className="bg-transparent relative  rounded-full">
                    <Avatar
                      width="w-28"
                      height="h-28"
                      loading={imageLoading}
                      error={errors.profile_img}
                      touched={touched.profile_img}
                      url={values.profile_img}
                    />
                    <label className="cursor-pointer mt-6 absolute bottom-1 bg-[#2A85FF] p-2 rounded-full right-0">
                      <FiCamera size={18} className="text-white text-sm" />
                      <input
                        type="file"
                        onChange={async (evt) => {
                          setImageLoading(true)
                         try {
                          console.log(evt.target.files?.[0]);
                          let url = await getMinifiedURL(evt.target.files?.[0]);
                          setFieldValue("profile_img", url);
                          setTouched("profile_img");
                         } catch (error) {
                           console.log(error)
                         }finally {
                           setImageLoading(false)
                         }
                        }}
                        className="hidden"
                      />
                    </label>
                  </div>
                </section>
                <section className="flex md:space-x-20 md:flex-nowrap flex-wrap space-y-7 md:space-y-0 items-center">
                  <Input
                    label="Name"
                    placeholder="Enter Admins Name"
                    name="name"
                    error={errors.name}
                    touched={touched.name}
                    value={values.name}
                    onChange={handleChange}
                  />
                  <Input
                    label="Shift Time"
                    name="shift_time"
                    value={values.shift_time}
                    onChange={handleChange}
                    error={errors.shift_time}
                    touched={touched.shift_time}
                    type="text"
                    placeholder="ex : 3am - 4pm"
                  />
                </section>
                <section className="flex md:space-x-20 md:flex-nowrap flex-wrap space-y-7 md:space-y-0 items-center">
                  <Input
                    label="Email address"
                    type="email"
                    name="email"
                    error={errors.email}
                    touched={touched.email}
                    value={values.email}
                    onChange={handleChange}
                    placeholder="eg: Test@example.com"
                  />
                  {user?.id == id ? (
                    <div className=" relative w-full">
                      <div className="pointer-events-none">
                      <Input
                        label="Password"
                        type="password"
                        placeholder=""
                        name="password"
                        //  value={values.password}
                        onChange={handleChange}
                      />
                      </div>
                      <div onClick={() => {
                        console.log("hi yhos change pasword")
                      }} className="text-blue-600 font-hel cursor-pointer absolute right-0 bottom-2 z-30 text-xs">Change password</div>
                    </div>
                  ) : (
                    [
                      id ? 
                      <div className="pointer-events-none relative w-full">
                      <Input
                      label="Password"
                      type="password"
                      placeholder="********"
                      name="password"
                      //  value={values.password}
                      onChange={handleChange}
                    /> 
                    </div>
                    : <Input
                    label="Password"
                    type="password"
                    placeholder="********"
                    name="password"
                     value={values.password}
                    onChange={handleChange}
                  />
                    ]
                  )}
                </section>
                {/* <section className="flex md:space-x-20 md:flex-nowrap flex-wrap space-y-7 md:space-y-0 items-center">
        <Input
          label="Change Password"
          placeholder="**********"
          type='password'
       
        />
        <div className='opacity-0 w-full'>
        <Input type='file' label="Driving License"  placeholder="" />
        </div>
      </section> */}
              </section>
              <section className="flex space-x-10 mt-16 mb-20">
                <FilledButton
                  type="button"
                  handleClick={() => {
                    
                    resetForm();
                    navigate(-1)
                  }}
                  filled
                >
                  Cancel
                </FilledButton>
                <FilledButton type="submit" loading={isSubmitting}>
                  Confirm
                </FilledButton>
              </section>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddProfile;
