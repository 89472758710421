interface RidesAndTipsCardInterface {
    type: string,  //rides or tips
    heading: string
    icon: any
    value: string,
    valueDescription?: string,
}

const RidesAndTipsCard = (props: RidesAndTipsCardInterface) => {
    return (
        <section className='bg-[#1A1D1F] rounded-lg flex flex-col items-center py-10 space-y-12'>
            <span className='font-semibold font-hel text-[#FCFCFC]'>
                {props.heading}
            </span>

            <div className="flex bg-[#434343] w-16 h-16 rounded-full justify-center items-center">
                <span>{props.icon}</span>
            </div>

            <div className="flex flex-col items-center">
                <span className=' font-normal font-hel text-[#FCFCFC]'>
                    {props.value}
                </span>
                {
                    props.type === "tips" &&
                    <span className="text-white text-xs mt-1">{props.valueDescription}</span>
                }
            </div>

        </section>
    )
}

export default RidesAndTipsCard;