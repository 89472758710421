import { FiSearch } from "react-icons/fi";
import MiniAvatar from "../../Components/MiniAvatar";
// import { BiBell } from "react-icons/bi";
import { useUserAuth } from "../../Context/UserAuthContext";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
type Props = {};

const DashboardHeader = (props: Props) => {
  const {  user,  handleToggelDrawer } = useUserAuth();
  const navigate = useNavigate()
  return (
    <section className="w-full py-2 bg-[#1A1D1F] flex justify-between items-center sticky top-0  z-20">
      <section className="flex-grow flex max-w-xs items-center">
        <HiOutlineMenuAlt1
          onClick={handleToggelDrawer}
          className="ml-4 md:hidden"
          color="white"
          size={25}
        />

        <section className="bg-[#272B30] w-full flex p-2 rounded-lg items-center ml-5 mr-3">
          <FiSearch size={20} color={"#6F767E"} />
          <input
            placeholder="Search or Type a command"
            className="bg-transparent w-full ml-3 text-xs md:text-sm outline-none 
                
                    caret-blue-600 text-white placeholder-[#6F767E]"
          />
        </section>
      </section>
      <section
        className="mr-4 flex items-center space-x-7"
      >
        {/* <section className="relative">
          <BiBell size={25} color="#6F767E" />
          <div className="absolute right-1 top-0 w-2 h-2 rounded-full bg-red-500"></div>
        </section> */}
        <div  className="cursor-pointer" onClick={() => {
              navigate(`AdminPanel/me/${user.id}`)
          }}>
          <MiniAvatar 
            url={user?.profile_img ?? null}
          />
        </div>
      
      </section>
    </section>
  );
};

export default DashboardHeader;
