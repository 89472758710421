import React from "react";
import { Outlet } from "react-router-dom";
import Logo from '../../Assets/logo.png'

type Props = {
    children ?: React.ReactNode
};

const Login = (props: Props) => {
  return (
    <section className="w-full  h-screen flex relative">
        <img className="w-40 h-40 hidden md:block object-contain z-30 absolute top-0 md:left-0 left-1/3" src={Logo} alt="logo"/>
      <section className="md:w-1/2 hidden sm:flex shrink bg-mainBackground h-full  bg-no-repeat bg-cover bg-center">
      </section>
      <section className="w-full md:px-0 px-5 bg-primary-lightBlack flex-grow 
      
      sm:w-1/2 h-full flex md:justify-center justify-start  flex-col items-center  
       
      ">
       <section className="h-1/4 md:hidden mb-6">
       <img
        className="w-40 h-40 object-contain z-30 md:hidden flex-initial"
        src={Logo}
        alt="logo"
      />
       </section>

        <Outlet/>
      </section>
    </section>
  );
};

export default Login;
