import { FilledStar, EmptyStar } from "../../../Assets/Home.svg";

interface RatingsCardRowInterface {
    star: number,
    guest: string
}

const RatingsCardRow = (props: RatingsCardRowInterface) => {
    return (
        <div className="flex items-center justify-between w-4/5 bg-[#434343] rounded px-3 py-3px">
            <span className=" flex flex-row gap-0.5">
                {
                    // dummy array of length five filled with zeros
                    new Array(5).fill(0).map((item, index) => {
                        if (index < props.star) {
                            return (<FilledStar />)
                        }
                        else {
                            return (<EmptyStar />)
                        }
                    })
                }
            </span>
            <span className=" text-white text-sm">{`${Number(props.guest) || 0}% guests`}</span>
        </div>
    )
}

export default RatingsCardRow;