
/* eslint-disable */
// @ts-nocheck
import { Route, Routes } from "react-router-dom";
import ForgotPasswordForm from "../Screens/Login/ForgotPassword/ForgotPasswordForm";
import LoginForm from "../Screens/Login/FormDetails/LoginForm";
import Login from "../Screens/Login/Login";

type Props = {};

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Login />}>
        <Route path="" element={<LoginForm />} />
        <Route path="ForgotPassword" element={<ForgotPasswordForm />} />
      </Route>
    </Routes>
  );
};

export default PublicRoutes;
