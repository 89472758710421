import React, { useState } from "react";
import { Document, Page } from "react-pdf";
// import { Loader } from "./Button/FilledButton/FilledButton";

type Props = {
  onClose?: () => void;
  onOpen?: () => void;
  open?: boolean;
  onConfrim?: () => void;
  onCancel?: () => void;
  url?: {
    type : string;
    link : string
  };
  type? : string
};

const DocumentViewModal = ({
  open,
  onClose,
  onOpen,
  onCancel,
  onConfrim,
  url
}: Props) => {
    const [pageNumber, setPageNumber] = useState(1)
    const [numPages, setNumPages] = useState(1)


    const prevPage = () => {
        if(pageNumber === 1) return
        setPageNumber(prev => prev -1)
    }

    const nextPage = () => {
        if(pageNumber === numPages) return
        setPageNumber(prev => prev + 1)
    }
  return (
    <div
      id="defaultModal"
      aria-hidden="true"
      className={`${
        open ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0  md:h-full flex justify-center items-center`}
    >
      
      <div className="relative p-4 w-full max-w-lg max-h-screen h-full md:h-auto">
      <button
              type="button"
              onClick={onClose}
              className="text-gray-400 bg-transparent absolute z-10 top-4 right-4 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="defaultModal"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
        {/* <!-- Modal content --> */}
        <div className="relative bg-white rounded-lg h-2/3 w-full shadow dark:bg-gray-700">
          {/* <!-- Modal header --> */}
        
        {
          url?.type === "pdf" ?  <>
          <Document
          file={url.link}
          onLoadSuccess={(pageNumber:any) => {
            console.log(
              "document loaded succesfully",pageNumber._pdfInfo.numPages
            );
            setNumPages(pageNumber._pdfInfo.numPages)
          }}
        >
          <Page pageNumber={pageNumber} />
        </Document>
          <div className="flex justify-center w-full mt-4">
              <button onClick={prevPage} className="px-1 rounded-md bg-white">{'<'}</button>
          <p className="bg-white">Page {pageNumber} of {numPages}</p> <button onClick={nextPage} className="px-1 rounded-md bg-white">{'>'}</button>
          </div> </> :  <div className="p-6 space-y-6">
           <img  src={url?.link} alt=""/>
          </div>
        }
            {/* <div className="">

            </div> */}
          {/* <!-- Modal body --> */}
         
          {/* <!-- Modal footer --> */}
        </div>
      </div>
    </div>
  );
};

export default DocumentViewModal;
