import React from 'react'
import AddDriverDetails from './AddDriverDetails'

type Props = {}

const Driver = (props: Props) => {
  return (
    <main className='my-12'>
      <AddDriverDetails/>
    </main>
  )
}

export default Driver