import Avatar from "../../../Components/Avatar";
import Input from "../../../Components/Input/Input";
import { FiCamera } from "react-icons/fi";
import FilledButton from "../../../Components/Button/FilledButton/FilledButton";
import { useLayoutEffect, useState } from "react";
import { getMinifiedURL } from "../../../Services/UploadImages";
import { Form, Formik } from "formik";
// @ts-ignore:
import "react-datepicker/dist/react-datepicker.css";
import { showError, showSuccess } from "../../../Components/Notifications";
import {
  addDriverDetails,
  getDriverByID,
  updateDriverDetailsById,
} from "../../../Services/CreateServices";
import {
  addDriverSchema,
  updateDriverSchema,
} from "../../../Configs/validation";
import { useNavigate, useParams } from "react-router-dom";
import LocalStorageService from "../../../Services/LocalStorageService";
import FileuploaderInput from "../../../Components/Input/FileUploaderInput";

interface IDriverDetails {
  driver_name?: string;
  driver_age?: string;
  driver_phone_no?: string;
  driver_image?: string;
  driver_password?: string;
  driving_experience?: string;
  driver_license?: string;
  driver_language?: string;
  driver_background?: string;
  filename?: string;
}

const AddDriverDetails = () => {
  const navigate = useNavigate();
  const [imageLoading, setImageLoading] = useState(false);
  const [driverDetails, setDriverDetails] = useState<IDriverDetails>({
    driver_age: "",
  });
  // const calendarREF = useRef<any>()
  const { id } = useParams();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    console.log("driver id", id);
    id && getDriverDetails();
    // eslint-disable-next-line
  }, [id]);

  const getDriverDetails = async () => {
    try {
      const { data } = await getDriverByID(id as string);
      setDriverDetails(data.data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="">
        <h1 className="text-4xl font-bold mb-10 text-white">
          {id ? "Update Driver" : "Add Driver"}
        </h1>
      </section>

      <Formik
        enableReinitialize
        initialValues={{
          driver_name: driverDetails?.driver_name ?? "",
          driver_age: driverDetails?.driver_age ?? "",
          driver_phone_no: driverDetails?.driver_phone_no ?? "",
          driver_image: driverDetails?.driver_image ?? "",
          driver_password: undefined,
          driving_experience: driverDetails?.driving_experience ?? "",
          driver_license: driverDetails?.driver_license ?? "",
          driver_language: driverDetails?.driver_language ?? "",
          driver_background: driverDetails?.driver_background ?? "",
          filename: driverDetails?.driver_license ? "licence uploaded" : "",
        }}
        validationSchema={id ? updateDriverSchema : addDriverSchema}
        onSubmit={async (
          values: IDriverDetails,
          { setErrors, setStatus, resetForm }
        ) => {
          console.log("csds");
          try {

           if(id)
           {
            const resp = await updateDriverDetailsById(id,{
              ...values,
              hotel: LocalStorageService.getParsedData("user").hotel,
            });
            console.log(resp);
            resp.data.status === 'error' ? 
            showError(
                resp.data.message,
                "Check Driver List"
            ) 
              :
            showSuccess(
                resp.data.message,
                "Check Driver List"
            )
           }
         else{
          const resp = await addDriverDetails({
            ...values,
            hotel: LocalStorageService.getParsedData("user").hotel,
          });
          console.log(resp);
          resp.data.status === 'error' ? 
          showError(
              resp.data.message,
              "Check Driver List"
          ) 
            :
          showSuccess(
              resp.data.message,
              "Check Driver List"
          )
         }


           
            resetForm();
            navigate("/Drivers");
          } catch (error) {
            const { message } = error as Error; // I removed : { message: string } because it should be infered
            console.log("ssd", message);
            showError(message, "check what inputs");
          } 
        }}
      >
        {({
          isSubmitting,
          handleChange,
          errors,
          values,
          touched,
          resetForm,
          setFieldValue,
          ...rest
        }) => {
          // console.log(values,"values");
          // console.log(rest,"rest")
          console.log(values, "myvaluescjheck");

          return (
            <Form autoComplete="chrome-off">
              <section className="bg-[#1A1D1F] rounded-md flex flex-col space-y-10 p-10">
                <section className="flex space-x-20 items-center">
                  <div className="bg-transparent relative  rounded-full">
                    <Avatar
                      loading={imageLoading}
                      width="w-28"
                      error={errors.driver_image}
                      touched={touched.driver_image}
                      url={values.driver_image}
                      height="h-28"
                    />
                    <label className="cursor-pointer mt-6 absolute bottom-1 bg-[#2A85FF] p-2 rounded-full right-0">
                      <FiCamera size={18} className="text-white text-sm" />
                      <input
                        type="file"
                        onChange={async (evt) => {
                          setImageLoading(true);
                          try {
                            console.log(evt.target.files?.[0]);
                            let url = await getMinifiedURL(
                              evt.target.files?.[0]
                            );
                            setFieldValue("driver_image", url);
                          } catch (error) {
                            console.log(error);
                          } finally {
                            setImageLoading(false);
                          }
                        }}
                        className="hidden"
                      />
                    </label>
                  </div>
                </section>
                <section className="flex md:space-x-20 md:flex-nowrap flex-wrap space-y-7 md:space-y-0 items-center">
                  <Input
                    label="Name"
                    touched={touched.driver_name}
                    onChange={handleChange}
                    error={errors.driver_name}
                    placeholder="Enter Driver's Name"
                    value={values.driver_name}
                    name="driver_name"
                  />
                  {/* <div className="w-full">
                  <DatePicker 
              selected={new Date(values.driver_age || 0)}
              
              ref={calendarREF}
              onChange={(date : Date) => {
                console.log(date)
                setFieldValue("driver_age",new Date(date).getTime())
              }}
              customInput={
                <div 
                style={{
                  width : "100%",
                  // backgroundColor : "red"
                }}
                onClick={() => {
                  calendarREF.current.open()
                }}>
                  <Input
                    label="Age"
                    // touched={touched.driver_age}
                    // error={errors.driver_age}
                    onChange={handleChange}
                    type="text"
                    placeholder=""
                    name="driver_age"
                    value={new Date(values.driver_age).toLocaleDateString()}
                  />
                </div>
              }
              /> 
                  </div> */}
                  <Input
                    type="number"
                    label="Age"
                    touched={touched.driver_age}
                    onChange={handleChange}
                    error={errors.driver_age}
                    placeholder="Enter Driver's Age"
                    value={values.driver_age}
                    name="driver_age"
                  />
                </section>
                <section className="flex md:space-x-20 md:flex-nowrap flex-wrap space-y-7 md:space-y-0 items-center">
                  <Input
                    label="Phone no."
                    type="tel"
                    touched={touched.driver_phone_no}
                    onChange={handleChange}
                    value={values.driver_phone_no}
                    name="driver_phone_no"
                    error={errors.driver_phone_no}
                    placeholder="enter driver's number"
                  />
                  <Input
                    label="Password"
                    onChange={handleChange}
                    error={errors.driver_password}
                    type="password"
                    touched={touched.driver_password}
                    placeholder=""
                    value={values.driver_password}
                    name="driver_password"
                  />
                </section>
                <section className="flex md:space-x-20 md:flex-nowrap flex-wrap space-y-7 md:space-y-0 items-center">
                  <Input
                    onChange={handleChange}
                    label="Driving Experience"
                    value={values.driving_experience}
                    touched={touched.driving_experience}
                    name="driving_experience"
                    error={errors.driving_experience}
                    placeholder=""
                  />

                  <div
                    className={`${
                      id && "pointer-events-none"
                    } w-full  relative`}
                  >
                    <FileuploaderInput
                    touched={touched.driver_license}
                      name="driver_license"             
                      value={values.filename}
                      error={errors.driver_license}
                      onChange={async (evt: any) => {
                        console.log(evt.target.files?.[0], "upload check");
                        if (!evt.target.files?.[0]) return;
                        let url = await getMinifiedURL(evt.target.files?.[0]);
                        setFieldValue("driver_license", url);
                        setFieldValue("filename", evt.target.files?.[0].name);
                      }}
                      label="Driver’s License"
                      filename="mylicence.txt"
                    />
                  </div>
                </section>
                <section className="flex md:space-x-20 md:flex-nowrap flex-wrap space-y-7 md:space-y-0 items-center">
                  <Input
                    onChange={handleChange}
                    label="Language Fluent in"
                    touched={touched.driver_language}
                    placeholder=""
                    error={errors.driver_language}
                    value={values.driver_language}
                    name="driver_language"
                  />
                  <Input
                    onChange={handleChange}
                    label="Background (opt)"
                    placeholder=""
                    value={values.driver_background}
                    name="driver_background"
                  />
                </section>
              </section>
              <section className="flex space-x-10 mt-16 mb-20">
                <FilledButton
                  type="button"
                  filled
                  handleClick={() => {
                    resetForm();
                  }}
                >
                  Clear
                </FilledButton>
                <FilledButton type="submit" loading={isSubmitting}>
                  Confirm
                </FilledButton>
              </section>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddDriverDetails;
