

/* eslint-disable */
// @ts-nocheck
import FilledButton from '../../../Components/Button/FilledButton/FilledButton'
import Input from '../../../Components/Input/Input'
import { BiPlus, BiMinus } from 'react-icons/bi';

type Props = {
    handleChange : any,
    values : any,
    errors : any,
    setFieldValue : any,
    touched : any
  };

const ExtendedDrive = (props: Props) => {
  return (
   <>
    <section className={`flex items-center justify-between `}>
    <h1 className="lg:text-4xl text-2xl font-bold my-10 text-white">
      Extend drive
    </h1>
    <section className="">
    <div >
          <FilledButton
          type={'button'}
          handleClick={() => {
            console.log("cda")
            if(props.values.extended) {
                props.setFieldValue('extended', false)
            } else {
                props.setFieldValue('extended', true)
    
            }
        }}
            children={"Extend"}
            loading={false}
            style={{
              paddingLeft: "1rem",
              paddingRight: "1rem",
              marginTop: 0,
              borderRadius : "0.5rem"
            }}
            icon={props.values.extended ?  <BiMinus size={20} className="mr-2" /> : <BiPlus size={20} className="mr-2" />}
          />
        </div>

    </section>
    </section>
    <section className={` bg-[#1A1D1F] rounded-md flex flex-col space-y-10 p-10 ${props.values.extended ? "" : "hidden"}`} >
    <section className='flex  md:space-x-20 md:flex-row flex-col space-y-7 md:space-y-0 items-center'>
    <Input label='Fare ($10 per mile)' name='fare'  placeholder='tufail hmad mor' value={props.values.fare} onChange={props.handleChange}/>
    <Input label='Enter Distance' name='distance' placeholder='Enter the Distance' onChange={props.handleChange} value={props.values.distance} />
    </section>
  
</section>
   </>
  )
}

export default ExtendedDrive