/* eslint-disable */
// @ts-nocheck
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllAdmins } from "../../Services/CreateServices";
import LocalStorageService from "../../Services/LocalStorageService";
import Table from "./Table";

type Props = {};

export interface ITableData {
  id?: string;
}

const AdminPanel = (props: Props) => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const [loading, setLoading] = useState(true);
  const ToggleMainPage = useCallback(() => {
    navigate("addProfile");
  }, []);

  useEffect(() => {
    getAdminPanelData();
  }, []);

  const getAdminPanelData = async () => {
    setLoading(true);
    try {
      console.log(LocalStorageService.getParsedData('user'),"checking hotel")
      const resp = await getAllAdmins(LocalStorageService.getParsedData('user').hotel);
      console.log("in admin panels table", resp.data.data);
      const filteredAdminData=resp.data.data.filter((e:any)=>{
             return e.disabled===false
      })
      setTableData(filteredAdminData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="my-12">
      {
        <Table
          fetchData={getAdminPanelData}
          ToggleScreen={ToggleMainPage}
          data={tableData}
          loading={loading}
        />
      }
    </main>
  );
};

export default AdminPanel;
