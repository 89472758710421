import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { FiImage } from "react-icons/fi";
import FilledButton, { Loader } from "../../Components/Button/FilledButton/FilledButton";
import Input from "../../Components/Input/Input";
import { showError, showSuccess } from "../../Components/Notifications";
import { addContentManagementSchema } from "../../Configs/validation";
import { addContentMgm } from "../../Services/CreateServices";
import { getMinifiedURL } from "../../Services/UploadImages";


type Props = {};

interface IContentManagement {

    image: string,
    heading: string,
    sub_heading: string,
    description: string

}


const AddContent = (props: Props) => {
  const [imageLoading, setImageLoading] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0)
  },[])
  return (
    <div className="mb-12">
      <section className="mt-12">

        <h1 className="lg:text-4xl text-2xl font-bold mb-10 text-white">
          Content Management
        </h1>
      </section>
      <Formik
          initialValues={{
            image: "",
            heading: "",
            sub_heading: "",
            description: ""
          }}
          validationSchema={addContentManagementSchema}
          onSubmit={async (
            values: IContentManagement,
            { setErrors, setStatus, resetForm }
          ) => {
            console.log("csds");
            try {
              console.log(values,"content management")
              const resp = await addContentMgm(values)
              console.log(resp?.data?.message);
              showSuccess(resp?.data?.message || "Content Added Successfully", "Check the List");
              resetForm();
            } catch (error) {
              const { message } = error as Error; // I removed : { message: string } because it should be infered
              console.log("ssd", message);
              showError(message || "something went wrong", "check what you are entering");
            }
          }}
        >
          {({
            isSubmitting,
            handleChange,
            errors,
            values,
            touched,
            resetForm,
            setFieldValue,
          }) => {
            console.log(values);
            return (
              <Form autoComplete="off">
      <section className="bg-[#1A1D1F] rounded-md flex flex-col space-y-10 p-10">
        <section
          className={`flex cursor-pointer group w-full md:w-1/3 aspect-square bg-[#272B30] 
           relative md:flex-nowrap justify-center  rounded-lg flex-wrap items-center`}
        >
          {imageLoading && <div className="flex justify-center items-center absolute w-full h-full top-0 left-0 z-20 bg-[#272B30] rounded-md">
                <Loader/> 
          </div>

          }
          {values.image && (
            <div className="absolute top-0 bg-contain bg-[#272B30] rounded-lg left-0 w-full h-full">
              <img src={values.image} alt="advertisement" className="h-full w-full  rounded-lg" />
            </div>
          )}
          <div className="flex cursor-pointer flex-col text-center justify-center items-center group-hover:z-40">
            <div className="bg-[#42474E] w-12 h-12   flex justify-center items-center rounded-full">
              <FiImage size={18} strokeWidth={2} color={"#979797"} />
            </div>
            <span className=" text-base font-hel leading-normal text-[#969696] mt-4">
              Upload image (20 mb max)
            </span>
          </div>
          <input
            type="file"

            onChange={async (evt) => {
              setImageLoading(true)
              try {
                console.log(evt.target.files?.[0]);
              let url = await getMinifiedURL(evt.target.files?.[0]);
              setFieldValue("image",url)
              } catch (error) {
                console.log(error)
              }finally {
setImageLoading(false)
              }
            }}
            className="opacity-0 absolute top-0 left-0 w-full h-full group-hover:z-40"
          />
        </section>
        <section className="flex  flex-col space-y-10 flex-wrap items-center">
          <Input
            label="Heading"
            name="heading"
            error={errors.heading}
            touched={touched.heading}
            onChange={handleChange}
            placeholder="ex: Four Seasons coming soon"
            value={values.heading}
          />
          <Input
            label="Sub Heading"
            error={errors.sub_heading}
            touched={touched.sub_heading}
            onChange={handleChange}
            name="sub_heading"
            type="text"
            placeholder="ex: Lets enjoy the seasons coming this year"
            value={values.sub_heading}
          />
          <Input
            label="Description"
            // error={errors.con}
            type="text"
            touched={touched.description}
            error={errors.description}
            name="description"
            onChange={handleChange}
            value={values.description}
            placeholder="ex: Lets enjoy the seasons coming this year"
          />
        </section>
      </section>
      <section className="flex space-x-10 mt-20">
        <FilledButton
           handleClick={resetForm}
          type="button"
          filled
        >
          Clear
        </FilledButton>
        <FilledButton
         loading={isSubmitting}
        >
          Confirm
        </FilledButton>
      </section>
      </Form>
            );
          }}

        </Formik>
    </div>
  );
};

export default AddContent;
