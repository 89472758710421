import React from 'react'
import Avatar from '../Avatar'
import { Loader } from '../Button/FilledButton/FilledButton'

type Props = {
  driverDetails : any
  loading : boolean
  error: any
}

const DriverDetailsCard = ({ driverDetails , loading, error}: Props) => {
  return (
    <section className={`text-white flex ${error ? "border-red-400 " : "border-[#1A1D1F]"} border  flex-col space-y-4  relative p-7 bg-[#1A1D1F] rounded-lg`}>
        <div className={`absolute z-20 top-0 left-0 h-full w-full rounded-lg
        ${loading ? "" : "hidden"}
         bg-[#1A1D1F] flex justify-center items-center`}>
        <Loader/>
        </div>
        <section className='flex space-x-4 items-center'>
            <Avatar url={driverDetails?.driver_image}/>
                <section className='flex flex-col'>
                    <span>{driverDetails?.driver_name ?? "No driver available"}, {driverDetails?.driver_age}</span>
                    <span>Driver</span>
                </section>
        </section>
        <section>
                <span>Language</span>
                <p>{
                 driverDetails?.driver_language
                  }</p>
        </section>
    </section>
  )
}

export default DriverDetailsCard