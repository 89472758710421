
/* eslint-disable */
// @ts-nocheck
import { Menu, MenuDivider, MenuItem } from "@szhsin/react-menu";
import { HiDotsVertical } from "react-icons/hi";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import React from "react";

interface IProps {
  action?: () => void;
  id: string;
  value: string;
  options?: {}[];
}

interface IMenuOptions {
  value: string;
  icon: React.ReactNode;
  handleClick: (id: string) => void;
}

const TableOptionsCard = (props: IProps) => {
  return (
    <section
      className="flex mr-3"
      style={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <div className="flex flex-col space-y-1 ">
        <span className="text-white font-hel font-normal  tracking-wider">
          {props.value}
        </span>
      </div>

      <div className="ml-4 ">
        <TableMenu MenuOptions={props.options} id={props.id} />
      </div>
    </section>
  );
};

export default TableOptionsCard;

const TableMenu = ({ MenuOptions, id }: any) => {
  return (
    <Menu
      className="bg-black"
      menuStyle={{
        backgroundColor: "#26292A",
        color: "white",
      }}
      menuButton={
        <div>
          <HiDotsVertical size={25} className="cursor-pointer text-white" />
        </div>
      }
    >
      {MenuOptions.map((option: IMenuOptions, index: number) => {
        return (
          <div key={index * 23}>
            <MenuItem
              onClick={() => {
                option.handleClick(id);
              }}
              className={(events) =>
                `flex space-x-3 text-white ${
                  events.hover ? `text-black` : `text-white`
                }`
              }
            >
              {option.icon} <span className="">{option.value}</span>
            </MenuItem>
            <MenuDivider
              className={`text-white ${
                index == MenuOptions.length - 1 && "hidden"
              }`}
            />
          </div>
        );
      })}

      <MenuDivider />
    </Menu>
  );
};
