import React from "react";

type Props = {
  status : string
};

const Status = (props: Props) => {
  const statusFilter = (status : string) => {
    switch (status.toLowerCase()) {
      case "accepted":
        return (
          <div className=" px-2 py-1 w-fit rounded bg-[#ffe6c03a]">
            <span className={`text-[#FF9F0A] font-hel capitalize text-sm font-light`}>
              {/* {status} */}
              Started
            </span>
          </div>
        );

      case "pending":
        return (
          <div className=" px-2  w-fit rounded py-1 capitalize  bg-[#cacaca31]">
            <span className={`text-[#9b9b9c] font-hel text-sm font-normal`}>
             {status}
            </span>
          </div>
        );
      case "cancelled":
        return (
          <div className=" px-2  w-fit rounded py-1 capitalize  bg-[#f0b2b240]">
            <span className={`text-[#e54d4d] font-hel text-sm font-light`}>
              {status}
            </span>
          </div>
        );
      case "completed":
        return (
          <div className=" px-2  w-fit rounded capitalize  bg-[#eafae435]">
            <span className={`text-[#83BF6E] font-hel text-sm font-light`}>
              {status}
            </span>
          </div>
        );
        case "online":
          return (
            <div className=" px-2  w-fit rounded capitalize  bg-[#eafae435]">
              <span className={`text-[#83BF6E] font-hel text-sm font-light`}>
                {status}
              </span>
            </div>
          );
      default:
        return  <div className=" px-2  w-fit rounded bg-[#97979780]">
        <span className={`text-[#D8D8D8] font-hel text-sm font-light`}>
          {status}
        </span>
      </div>
      // code block
    }
  };
  return <>
    {statusFilter(props.status)}
  </>;
};

export default Status;
