import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Authentication from "./Routes/Authentication";
import { UserAuthContextProvider } from "./Context/UserAuthContext";
import { Toaster } from "react-hot-toast";
function App() {
  return (
    <div className=" bg-black w-full">
      <main className="4xl:container md:mx-auto">
      <Toaster/>
        <BrowserRouter>
          <UserAuthContextProvider>
            <Routes>
              <Route path="/*" element={<Authentication />} />
            </Routes>
          </UserAuthContextProvider>
        </BrowserRouter>
      </main>
    </div>
  );
}

export default App;
