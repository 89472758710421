import { useState, useEffect } from "react";
import Status from "../../Components/Status";
import BookingDetailsField from "./BookingDetailsField";
import { getBookingDetails } from "../../Services/CreateServices";
import { Loader } from "../../Components/Button/FilledButton/FilledButton";
import { useParams } from "react-router-dom";

const BookingDetails = () => {
  const [BookingDetailsData, setBookingDetailsData] = useState<any>();
  const { id } = useParams();

  useEffect(() => {
    const getBookingDetailsData = async () => {
      try {
        const resp = await getBookingDetails(id || "");
        setBookingDetailsData(resp.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    id && getBookingDetailsData();
  }, [id]);

  return (
    <main className="my-12 relative">
      <h1 className="lg:text-4xl text-base md:text-2xl font-bold mb-10 text-[#EFEFEF]">
        Booking Details
      </h1>
      {
        BookingDetailsData ?
          <section className="bg-[#1A1D1F] rounded-md flex flex-col space-y-10 p-10 mb-10">
            <section className="flex md:space-x-20 md:flex-nowrap flex-wrap space-y-7 md:space-y-0 items-center">
              <div className={`w-full`}>
                <h1 className="lg:text-2xl md:text-xl font-bold text-white ">Current Ride</h1>
              </div>
              <div className={`w-full flex flex-row justify-between items-center `}>
                <p className=" text-white">Status</p>
                <Status status={BookingDetailsData.status.toLowerCase()} />
              </div>
            </section>

            <section className="flex md:space-x-20 md:flex-nowrap flex-wrap space-y-7 md:space-y-0 items-center">
              <BookingDetailsField title="Guest name" desc={BookingDetailsData.guestName} />
              <BookingDetailsField title="Driver" desc={BookingDetailsData.driver.driver_name} />
            </section>

            <section className="flex md:space-x-20 md:flex-nowrap flex-wrap space-y-7 md:space-y-0 items-center">
              <BookingDetailsField title="Room no." desc={BookingDetailsData.guestRoomNo} />
              <BookingDetailsField title="Car Details"
                desc={`${BookingDetailsData.car.car_brand_name}-${BookingDetailsData.car.car_model_no} ${BookingDetailsData.car.car_number}`}
              // desc="Mercedes-Benz E350 Sedan" 
              />
            </section>

            <section className="flex md:space-x-20 md:flex-nowrap flex-wrap space-y-7 md:space-y-0 items-center">
              <BookingDetailsField title="Pick Location" desc={BookingDetailsData.formatted_pick_address} />
              <BookingDetailsField title="Drop Location" desc={BookingDetailsData.formatted_drop_address} />
            </section>

            <section className="flex md:space-x-20 md:flex-nowrap flex-wrap space-y-7 md:space-y-0 items-center">
              <BookingDetailsField title="Tip" desc={BookingDetailsData?.review?.tips ? "$" + BookingDetailsData?.review?.tips : "$1"}
                disabled={BookingDetailsData?.review?.tips ? false : true} />
              <BookingDetailsField title="Feedbacks" desc={BookingDetailsData.feedback_by_guest ? BookingDetailsData.feedback_by_guest : "Feedbacks"}
                disabled={BookingDetailsData.feedback_by_guest ? false : true} />
            </section>
          </section>
          :
          <section
            className="w-full h-full flex justify-center items-center border-dotted rounded-sm border-gray-400 ">
            <Loader />
          </section>
      }
    </main>
  );
};

export default BookingDetails;
