/* eslint-disable */
import { useEffect, useState } from 'react'
import { getAllDrivers } from '../../../Services/CreateServices'
import DriverListTable from './DriverListTable'

type Props = {}

const Drivers = (props: Props) => {
    const [loading, setLoading] = useState(false)
    const [allDriver, setAllDrivers] = useState([])

    useEffect(() => {
        getAllDriversData()
    },[])

    const getAllDriversData = async () => {
        setLoading(true)
        try {
          const { data } = await getAllDrivers()
          console.log("all Driver",data.data)
          setAllDrivers(data.data.filter((e:any)=>{
               return e.disabled===false
          }))
        } catch (error) {
            console.log(error)
        }finally{
          setLoading(false)
        }
      }
  return (
    <section role="driver-list-container" className='my-12'>
        <DriverListTable getAllDriversData={getAllDriversData} data={allDriver} loading={loading}/>
    </section>
  )
}

export default Drivers