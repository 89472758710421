import { NavLink } from "react-router-dom";
import {
  Home,
  Driver,
  Car,
  Guest,
  AdminPanel,
  FileText,
  Logout,
} from "../../Assets/Home.svg";
import { useUserAuth } from "../../Context/UserAuthContext";
import { IoClose } from "react-icons/io5";
type Props = {};

const SideBarNavigator = (props: Props) => {
  const { toggleDrawer, handleToggelDrawer , logOut} = useUserAuth();

  const active =
    "bg-[#272B30] text-[#FCFCFC] shadow-inner shadow-md text-sm p-3 rounded-lg flex items-center space-x-4";
  const inActive = "text-[#6F767E] p-3  text-sm flex items-center space-x-4 ";
  const PATHS = [
    {
      path: "/",
      name: "Home",
      Icon: <Home />,
      id : 3121
    },
    {
      path: "Drivers",
      name: "Driver",
      Icon: <Driver />,
      id : 12211
    },
    {
      path: "GetAllCars",
      name: "Car",
      Icon: <Car />,
      id : 555
    },
    {
      path: "GuestQue",
      name: "Guest Queue",
      Icon: <Guest />,
      id : 6776
    },
    {
      path: "ContentMgm",
      name: "Content Management",
      Icon: <FileText />,
    },
    {
      path: "AdminPanel",
      name: "Admin Panel",
      Icon: <AdminPanel />,
      id :  342
    },
  ];
  return (
    <section
      className={`md:w-60 lg:w-80 z-40   transition-width 
     ease-in-out absolute overflow-hidden max-h-screen md:static 
    ${toggleDrawer ? "w-80" : "w-0"}
     md:shadow-none
     bg-[#1A1D1F] shadow-2xl shadow-black top-0 bottom-0 md:h-auto  `}
    >
      <IoClose
        onClick={handleToggelDrawer}
        className="text-white right-5 
         top-5
         absolute
          md:hidden "
        size={25}
      />

      <section className={`flex flex-col mx-5 space-y-3 mt-20 `}>
        {PATHS.map((item, index) => (
          <NavLink
          key={item.id}
            to={item.path}
            onClick={handleToggelDrawer}
            className={({ isActive }) => (isActive ? active : inActive)}
          >
            <div>{item.Icon}</div>
            <span>{item.name}</span>
          </NavLink>
        ))}
      </section>
   
    
       <div
        onClick={() => {
          logOut()
        }}
        className={` hover:${active} ${inActive} mx-5 z-20 mb w-fit h-9 cursor-pointer bottom-5 absolute`}>
          <div>
            <Logout/>
          </div>
          <span>Logout</span>
        </div>
   
    </section>
  );
};

export default SideBarNavigator;
