/* eslint-disable */
import React, { useRef, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Column, useSortBy, useTable } from "react-table";
import { Bin, Edit, FileText } from "../../../Assets/Home.svg";
import FilledButton from "../../../Components/Button/FilledButton/FilledButton";
import ConfirmationModal from "../../../Components/ConfirmationModal";
import DocumentViewModal from "../../../Components/DocumentViewModal";
import EmptyStateData from "../../../Components/EmptyStateData";
import MiniAvatar from "../../../Components/MiniAvatar";
import TableDataLoader from "../../../Components/TableDataLoader";
import { deleteDriverByID } from "../../../Services/CreateServices";
import TableOptionsCard from "../../Booking/TableOptionsCard";

interface SelectedCar{
  _id:string,car:{car_brand_name:string,car_colour:string,car_image:string,car_model_no:string,car_number:string,_id:string
  },category:{name:string;_id:string}
}


type Props = {
  loading?: boolean;
  getAllDriversData:()=>{}
  data: {
    _id?: string ;
    driver_name: string;
    driver_age: string;
    added_by: string;
    driver_phone_no: string;
    driver_image: string;
    driver_password: string;
    driving_experience: string;
    driver_license: string;
    reports: string;
    driver_language: string;
    driver_background: string;
    selected_car:SelectedCar
  }[];
};



const DriverListTable = ({ loading, data,getAllDriversData }: Props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [url, setURL] = useState({
    type: "",
    link : ""
  })
  const [tableLoading, setTableLoading] = useState(false)
  const driverID = useRef<string>()
  const ToggleModal = () => {
      setOpen(prev  => !prev)
  }

  const ToggleConfirmationModal = () => {
    setOpenConfirmation(prev => !prev)
  }

  const deleteDriver = async () => {
    console.log("delete driver")
    try {
      const resp = await deleteDriverByID(driverID.current ?? "")
      console.log(resp)
      ToggleConfirmationModal()
      getAllDriversData()
    } catch (error) {
      console.log(error)
    }
  }

  const showDrivingLicence = (url:string) => {
      if(url.includes(".pdf")){
        setURL({
          type : "pdf",
          link : url
        })
        
      } else {
        setURL({
          type : "image",
          link : url
        })
   
      }
      setOpen(true)
      console.log("yes")
  }





  const MenuOptions = [
    
    {
      option: "Edit",
      icon: <Edit />,
      handleClick: (id: string) => {
        console.log("this is the edit", id);
        navigate(`edit/${id}`)
  
      },
    },
    {
      option: "Delete",
      icon: <Bin />,
      handleClick: (id: string) => {
        driverID.current = id
        setOpenConfirmation(true)
      },
    },
  ];



  const columns: Array<
    Column<{
      
      _id?: string;
      driver_name: string;
      driver_age: string;
      added_by: string;
      driver_phone_no: string;

      driver_image: string;
      driver_password: string;
      driving_experience: string;
      driver_license: string;
      reports: string;
      driver_language: string;
      driver_background: string;
      selected_car:SelectedCar;
    }>
  > = React.useMemo(
    () => [
      {
        Header: "Driver",
        accessor: "driver_name", // accessor is the "key" in the data
        Cell: (props) => {
          return (
            <div className="text-left   h-12 text-white font-hel 
            font-light  flex justify-start alignCenter">
             <div className="flex-1">
             <MiniAvatar url={props.cell.row.original.driver_image} />
             </div>
              <span className="w-24">  {props.value}</span>
            </div>
          );
        },
      },
      {
        Header: "Phone Number",
        accessor: "driver_phone_no",
        Cell: (props) => {
          return (
            <div className="text-left text-white font-hel font-light tracking-wide">
              {props.value}
            </div>
          );
        },
      },

      {
        Header: "Driver Age",
        accessor: "driver_age",
        Cell: (props) => {
          return <div>
            {props.value}
          </div>;
        },
      },
      {
        Header: "Experience",
        accessor: "driving_experience",
        Cell: (props) => {
          return (
            <div className="text-left text-white font-hel font-light tracking-wide">
              {props.value}
            </div>
          );
        },
      },
      {
        Header: "Car Details",
        accessor: "selected_car",
        Cell: (props) => {
          console.log(props.value,"this is car dertil")
          return (
            <div className="text-left text-white font-hel font-light tracking-wide">
              { props?.value?.car?.car_number ?? "no data"}
            </div>
          );
        },
      },
      {
        Header: "Language",
        accessor: "driver_language",
        Cell: (props) => {
          return (
            <div className="text-left text-white font-hel font-light tracking-wide">
              {props.value}
            </div>
          );
        },
      },
      {
        Header: "Driving License",
        accessor: "driver_license",
        Cell: (props) => {
          return (
            <div onClick={() => {
                showDrivingLicence(props.value)
            }} className="p-1 w-20 cursor-pointer relative center group   text-[#2A85FF] font-hel text-base  
             rounded-md flex justify-center bg-[#2a86ff1f]  font-light tracking-wide">
              <div  className="bg-red-400 absolute hidden  top-0 h-24 aspect-square
               left-0 w-24 object-contain z-40">
              <img src={props.value} alt="d" className="w-full h-full"/>
              </div>
             <FileText /> <span className="text-[#2A85FF] ml-1">Open</span>
            </div>
          );
        },
      },
      {
        Header: "Reports",
        accessor: "reports",
        Cell: (props) => {
          return (
            <div onClick={() => {
              
              console.log("oye", props.cell.row.original._id)
              navigate(`driverReports/${props.cell.row.original._id}`)

            }} className=" -ml-2 p-1 w-20 cursor-pointer relative center group   text-[#2A85FF] font-hel text-base  
             rounded-md flex justify-center bg-[#2a86ff1f]  font-light tracking-wide">
              <div  className=" absolute hidden  top-0 h-24 aspect-square
               left-0 w-24 object-contain z-40">
              <img src={props.value} alt="d" className="w-full h-full"/>
              </div>
             <FileText /> <span className="text-[#2A85FF] ml-1">View</span>
            </div>
          );
        },
      },
        {
          Header: "Background (opt)",
          accessor: "driver_background",
          Cell: (props) => {
        // console.log(props,"i am checking ,my props")
            return  <TableOptionsCard 
            options={MenuOptions}
            id={props.cell.row.original._id || ""}
            value={props.value}
            />;
          },
        },
    ],
    []
  );

  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );
  return (
    <>
      <section role="car-list-table">
      <ConfirmationModal
          open={openConfirmation}
          onClose={ToggleConfirmationModal}
          onConfrim={deleteDriver}
          onOpen={() => {}}
          title=" Are you sure you want to delete this driver"
          loading={tableLoading}
          subtitle=""
        />
          <DocumentViewModal url={url} open={open} onClose={ToggleModal} />
        <div
          className="flex"
          style={{ justifyContent: "space-between", alignItems: "flex-start" }}
        >
          <h3 className="lg:text-2xl text-1xl font-bold mb-10 text-[#EFEFEF]">
            All Drivers
          </h3>
          <div
            onClick={() => {
              navigate("Add");
            }}
          >
            <FilledButton
              children={"New Driver"}
              loading={false}
              style={{
                paddingLeft: "1rem",
                paddingRight: "1.5rem",
                marginTop: 0,
                borderRadius: "0.8rem",
              }}
              icon={<BiPlus size={20} className="mr-2" />}
            />
          </div>
        </div>
      </section>
      <div className="bg-[#1A1D1F] carList  overflow-x-auto p-7 2xl:max-w-6xl rounded-lg">
        <table
          {...getTableProps()}
          className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <thead className="text-xs text-white uppercase bg-[#26292A]">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index, columns) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`px-6 ${
                      index == columns.length ? "text-center" : "text-left"
                    }  py-5 font-medium whitespace-nowrap`}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tr className="p-10">
            <th className="p-2"></th>
          </tr>
          {!loading && rows.length > 0 && (
            <tbody>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="border-t  border-[#6F767E] border-opacity-50"
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="pl-6  py-4 m-0 "
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {loading && (
          <div className="w-full  h-44 ">
            <TableDataLoader />
          </div>
        )}
        {!loading && rows.length < 1 && (
          <div className="w-full h-44 ">
            <EmptyStateData />
          </div>
        )}
      </div>
    </>
  );
};

export default DriverListTable;
