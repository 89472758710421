import { useEffect, useState, useLayoutEffect } from "react";
import { BiPlus } from "react-icons/bi";
import { pdfjs } from "react-pdf";
import { useNavigate } from "react-router-dom";
import FilledButton from "../../Components/Button/FilledButton/FilledButton";

// import DocumentViewModal from "../../Components/DocumentViewModal";
import {
  getAllBookings,
  getDriversOnDuty,
} from "../../Services/CreateServices";
import CurrentRideCard from "./CurrentRideCard";
import EmptyStateData from "../../Components/EmptyStateData";
import Table from "./Table";
import { DriverlocationMap } from "./DriversLocationMap";

type Props = {};
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
const Booking = (props: Props) => {
  const navigate = useNavigate();
  const [bookingData, setBookingData] = useState<any>([]);
  const [driversOnDuty, setDriversOnDuty] = useState<{}[]>([]);
  const [loading, setLoading] = useState(true);
  // pagination

  const [paginatedData, setPaginatedData] = useState<any>([]);
  const [paginationCount, setPaginationCount] = useState<number>(1);

  useEffect(() => {
    var arr = [];
    for (let i = (paginationCount - 1) * 10; i < paginationCount * 10; i++) {
      if (i < bookingData.length) {
        arr.push(bookingData[i]);

      }
    }
           setPaginatedData([...arr]);

  }, [bookingData, paginationCount]);

  // pagination

  useEffect(() => {


      getAllBookingsData();

   


  }, []);

  useLayoutEffect(() => {

    let myinterval=setInterval(()=>{
      getAllbookingDatasecondTime()

    },5000)

    return ()=> clearInterval (myinterval)

  }, []);



const getAllbookingDatasecondTime=async ()=>{


  try {
    const allbookings = getAllBookings();
    const driversOnDuty = getDriversOnDuty();
    const allresponses = await Promise.all([allbookings, driversOnDuty]);
    console.log("csdc", allresponses);
    console.log(allresponses[0].data.data, "checking data home");
    const sortedBookinglist = allresponses[0]?.data?.data
      .sort((a: any, b: any) => {
        return b.priority - a.priority;
      })

      let pending=sortedBookinglist.filter((e:any)=>{return e.status==="pending"})
      let accepted=sortedBookinglist.filter((e:any)=>{return e.status==="accepted"})
      let completed=sortedBookinglist.filter((e:any)=>{return e.status==="completed"})
    
    setBookingData([...accepted,...pending,...completed]);
    setDriversOnDuty(
      allresponses[1]?.data?.data.filter((e: any) => {
        return e.is_available;
      })
    );
  } catch (error) {
    console.log(error);
  }


}



  const getAllBookingsData = async () => {
    setLoading(true);
    try {
      const allbookings = getAllBookings();
      const driversOnDuty = getDriversOnDuty();
      const allresponses = await Promise.all([allbookings, driversOnDuty]);
      console.log("csdc", allresponses);
      console.log(allresponses[0].data.data, "checking data home");
      const sortedBookinglist = allresponses[0]?.data?.data
        .sort((a: any, b: any) => {
          return b.priority - a.priority;
        })

        let pending=sortedBookinglist.filter((e:any)=>{return e.status==="pending"})
        let accepted=sortedBookinglist.filter((e:any)=>{return e.status==="accepted"})
        let completed=sortedBookinglist.filter((e:any)=>{return e.status==="completed"})
      
      setBookingData([...accepted,...pending,...completed]);
      setDriversOnDuty(
        allresponses[1]?.data?.data.filter((e: any) => {
          return e.is_available;
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="my-12 relative">
      <div
        className="flex"
        style={{ justifyContent: "space-between", alignItems: "flex-start" }}
      >
        <h1 className="lg:text-4xl text-base md:text-2xl font-bold mb-10 text-[#EFEFEF]">
          Booking Details
        </h1>
        <div
          onClick={() => {
            navigate("Booking");
          }}
        >
          <FilledButton
            children={"New Booking"}
            loading={false}
            style={{
              paddingLeft: "1rem",
              paddingRight: "1.5rem",
              marginTop: 0,
              borderRadius: "0.8rem",
            }}
            icon={<BiPlus size={20} className="mr-2" />}
          />
        </div>
      </div>

      <Table
        fetchData={getAllBookingsData}
        data={paginatedData}
        loading={loading}
        paginationCount={paginationCount}
        setPaginationCount={setPaginationCount}
        bookingdata={bookingData}
      />
      <h3 className="lg:text-2xl text-1xl  mt-10 font-bold mb-10 text-[#EFEFEF]">
        Drivers on duty
      </h3>
      <section
        aria-roledescription="show-current-rides"
        className={
          driversOnDuty.length !== 0
            ? "grid lg:grid-cols-2 grid-cols-1 space-y-4 md:space-y-0 md:gap-6"
            : "justify-center"
        }
      >
        {driversOnDuty.length === 0 ? (
          <EmptyStateData />
        ) : (
          driversOnDuty.map((driver, index) => (
            <CurrentRideCard key={index} driver={driver} />
          ))
        )}
      </section>
      <div style={{ height: "40px" }}></div>
      <DriverlocationMap />
    </main>
  );
};

export default Booking;
