interface BookingDetailsFieldProps {
  title: string,
  desc: string,
  disabled?: boolean
}

const BookingDetailsField = ({ title, desc, disabled }: BookingDetailsFieldProps) => {
  return (
    <div className="w-full border-0 border-b border-[#969696] bg-transparent">
      <p className=" text-white pb-2 md:pb-3 lg:pb-4">{title}</p>
      <p className={`${disabled ? "text-[#A9A9A9]" : "text-white"} pb-2 md:pb-3 lg:pb-4`}>{desc}</p>
    </div>
  );
};

export default BookingDetailsField;
