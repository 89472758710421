import { FiUpload } from "react-icons/fi";
import { TiWarningOutline } from "react-icons/ti";
type Props = {
  placeholder?: string;
  label?: string;
  error?: string;
  value?: string;
  onChange?: any;
  type?: string;
  large?: Boolean;
  name?: string;
  touched?: boolean;
  filename: string;
};

const FileuploaderInput = (props: Props) => {
  console.log(props,"fileuploader prop")
  const errorStyles =
    props.error && props.touched ? "text-red-400 " : "text-white";
  return (
    <div className="flex flex-col items-start w-full relative">
      <label className="mb-3 text-white text-base">{props.label}</label>

      {/* <FiCamera size={18} className="text-white text-sm" /> */}

      <div
        className={`w-full border-0 pb-2 border-b focus:border-white border-[#969696] ${errorStyles} bg-transparent`}>
        <p className={props.value ? "text-white" : "text-gray-600 text-lg"}>
          {props.value ? props.value : "Upload Drivers's Licence"}
        </p>
      </div>

      <label className="cursor-pointer mt-6 absolute bottom-1 bg-[#2A85FF] p-2 rounded-full right-7">
        <FiUpload size={18} className="text-white text-sm" />

        <input type="file" onChange={props.onChange} className="hidden" />
      </label>

      {!props.value && props.touched && (
        <TiWarningOutline
          className=" absolute right-1 top-1/2 text-red-400 "
          size={20}
        />
      )}
    </div>
  );
};

export default FileuploaderInput;
