import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import { storage } from "../Configs/firebase-config"

export const getMinifiedURL = async (file : any) => {
    let time = new Date().valueOf()
    const storageRef = ref(storage, `images/${time}/${file.name}` )
    const resp = await uploadBytes(storageRef,file)
    const downloadURL = await getDownloadURL(resp.ref)
    console.log(downloadURL)
    return downloadURL
    
  }