
/* eslint-disable */
// @ts-nocheck
import {
  // onAuthStateChanged,
  // signInWithEmailAndPassword,
  // signOut,
  sendPasswordResetEmail,
  // User,
} from "firebase/auth";
import { createContext, useContext,  useLayoutEffect, useState } from "react";
import { auth } from "../Configs/firebase-config";
import LocalStorageService from "../Services/LocalStorageService";

export const userAuthContext = createContext({} as any);

interface Props {
  children: React.ReactChild;
}

interface UserData {
  token?: string;

}

const parseData = (data: any) => {
  return JSON.parse(data);
};
export const UserAuthContextProvider = (props: Props) => {
  const [user, setUser] = useState<UserData | null>(parseData(localStorage.getItem("user")));
  const [toggleDrawer, setToggleDrawer] = useState(false);

  useLayoutEffect(() => {
    checkuserData()
  }, []);

  const checkuserData = () => {
    let userData = parseData(localStorage.getItem("user"));
    userData && setUser(userData);
  }

 

  

  const updateUser = async (userData: UserData, rememberMe ?: boolean) => {
    console.log("updateUser", userData);
    if (userData?.token) {
      LocalStorageService.setToken(userData.token)
      // localStorage.setItem("token", JSON.stringify(userData.token));
    }
    LocalStorageService.setUser(userData)
     
    setUser(userData);
    if(rememberMe) return
    LocalStorageService.setRefreshTokenTime()

  };

  const setUserDetails = (currentUser: { message: string }) => {
    let updatedUser = {...user, ...currentUser}
    setUser(updatedUser as any);
    localStorage.setItem("user", JSON.stringify(updatedUser));
  };

  const ResetPassword = (email: string) => {
    return sendPasswordResetEmail(auth, email);
  };

  const handleToggelDrawer = () => {
    setToggleDrawer((prev) => !prev);
  };

  const logOut = () => {
    LocalStorageService.clearToken()
    setUser(null);
    // return signOut(auth);
  };
  return (
    <userAuthContext.Provider
      value={{
        user,
        toggleDrawer,
        updateUser,
        logOut,
        handleToggelDrawer,
        ResetPassword,
        setUserDetails,
      }}
    >
      {props.children}
    </userAuthContext.Provider>
  );
};

export const useUserAuth = () => {
  return useContext(userAuthContext);
};

export const useGeneralContext = () => {
  return useContext(userAuthContext);
};
