import * as Yup from "yup";

const regex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
export const loginSchema = Yup.object({
  email: Yup.string().email("Email is invalid").required("Field is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Field is required"),
});

export const forgotPasswordSchema = Yup.object({
  email: Yup.string().email("Email is invalid").required("Field is required"),
});

export const addDriverSchema = Yup.object({
  // email: Yup.string().email('Email is invalid').required('Field is required'),
  driver_name: Yup.string().required("Field is required"),
  driver_password: Yup.string().required("Field is required")
    .min(4, "Password must be at least 4 characters")
    ,
  driver_age: Yup.number().typeError('you must specify a number').required("Field is required").min(18,"Driver should be more than 18 years old"),
  driver_phone_no: Yup.string().matches(regex, 'Phone number is not valid').required("field is required"),
  driver_image: Yup.string().required("Field is required"),
  driving_experience: Yup.string().required("Field is required"),
  driver_license: Yup.string().required("Field is required"),
  driver_language: Yup.string().required("Field is required"),
});


export const updateDriverSchema = Yup.object({
  // email: Yup.string().email('Email is invalid').required('Field is required'),
  driver_name: Yup.string().required("Field is required"),
  // driver_password: Yup.string().required("Field is required")
  //   .min(4, "Password must be at least 4 characters")
  //   ,
  driver_age: Yup.string().required("Field is required"),
  driver_phone_no: Yup.string().matches(regex, 'Phone number is not valid').required("field is required"),
  driver_image: Yup.string().required("Field is required"),
  // driving_experience: Yup.string().required("Field is required"),
  driver_license: Yup.string().required("Field is required"),
  driver_language: Yup.string().required("Field is required"),
});

export const addCarDetailsSchema = Yup.object({
  // email: Yup.string().email('Email is invalid').required('Field is required'),
  car_image: Yup.string().required("Field is required"),
  car_brand_name: Yup.string().required("Field is required"),
  car_model_no: Yup.string().required("Field is required"),
  car_category: Yup.string().required("Field is required"),
  car_number: Yup.string().required("Field is required"),
  car_colour: Yup.string().required("Field is required"),
});

export const addBookingForCarSchema = Yup.object({
  guestName: Yup.string().required("Field is required"),
  guestEmail: Yup.string()
    .email("Email is invalid")
    .required("Field is required"),
  guestRoomNo: Yup.string().required("Field is required"),
  guestContactNo: Yup.string()
  .matches(/^[0-9+-]*$/, 'Only numbers and +, - are allowed')
  .max(15, 'Max 15 characters allowed')
  .required('Required'),
  pick_location: Yup.object().shape({
    coordinates: Yup.array()
      .min(2, "at least 2 cordinates")
      .required("required"),
  }),
  formatted_pick_address: Yup.string().required("Field is required"),
  drop_location: Yup.object().shape({
    coordinates: Yup.array()
      .min(2, "at least 2 cordinates")
      .required("required"),
  }),
  formatted_drop_address: Yup.string().required("Field is required"),
  car_category: Yup.string().required("Field is required"),
  car: Yup.string().required("Field is required"),
  driver: Yup.string().required("Field is required"),
});

export const addContentManagementSchema = Yup.object({
  // email: Yup.string().email('Email is invalid').required('Field is required'),
  image: Yup.string().required("Field is required"),
  heading: Yup.string().required("Field is required"),
  sub_heading: Yup.string().required("Field is required"),
  description: Yup.string().required("Field is required"),
});


export const addProfileSchema = Yup.object({
  // email: Yup.string().email('Email is invalid').required('Field is required'),
  name : Yup.string().required("Field is required"),
  profile_img: Yup.string().required("Field is required"),
  email: Yup.string().required('Field is required').email('Email is invalid'),
  shift_time: Yup.string().required("Field is required"),
  password: Yup.string().required("Field is required"),

});
