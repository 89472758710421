
/* eslint-disable */
// @ts-nocheck
import { Menu, MenuDivider, MenuItem } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { Bin, Edit, Up } from '../../Assets/Home.svg';
import { BiFilterAlt } from 'react-icons/bi';
// type Props = {
//     action: () => void
//     data: any
//     value: string
// }

const MenuOptions = [{
    option: "Today",
    icon: <Up />
},
{
    option: "This week",
    icon: <Edit />
},
{
    option: "This Year",
    icon: <Bin />
},

]


const BookingFilter = (props: any) => {
    return (
        <section className='flex mr-3' style={{ justifyContent: 'space-between', alignItems: 'center', maxWidth: 150 }}>
            {/* <div className='flex flex-col space-y-1 '>
                <span className='text-white font-hel font-normal  tracking-wider'>
                    {props.value}
                </span>
            </div> */}

            <div className='ml-4 '>
                <TableMenu />
            </div>
        </section>
    )
}

export default BookingFilter


const TableMenu = () => {
    return <Menu className="bg-black" menuStyle={{
        backgroundColor: '#26292A',
        color: "white"
    }} menuButton={<div className='filter'>
        <BiFilterAlt size={25} className="cursor-pointer text-white" />
    </div>}>
        {
            MenuOptions.map((option, index, options) => {
                return <div key={index * 23}>
                    <MenuItem id='filterMenuItem' className={(events) => `flex space-x-3 text-white ${events.hover ? `text-black` : `text-white`}`}>
                        <span className=''>{option.option}</span>
                    </MenuItem>
                    <MenuDivider className={`text-white ${index == options.length - 1 && 'hidden'}`} />
                </div>
            })
        }

        <MenuDivider />

    </Menu>
}