
import { Loader } from "./Button/FilledButton/FilledButton";

type Props = {};

const  TableDataLoader = (props: Props) => {
  return (
    <section
      className="w-full h-full
    flex justify-center items-center
     border-dotted rounded-sm border-gray-400 "
    >
      <Loader />
    </section>
  );
};

export default TableDataLoader;
